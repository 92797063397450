import classNames from 'classnames';
import style from './TopNav.module.css';
import type {ReactNode} from 'react';

export interface Props {
	children: ReactNode;
	active: boolean;
	centered?: boolean;
	sticky?: boolean;
	className?: string;
}

export function TopNav({
	children,
	active,
	centered = false,
	sticky = true,
	className,
}: Props) {
	const navContainerClasses = classNames(
		style.NavContainer,
		active && style.NavContainerActive,
		centered && style.NavContainerCentered,
		sticky && style.NavContainerSticky,
	);

	const navClasses = classNames(className, style.Nav);

	return (
		<div className={navContainerClasses}>
			<div className={navClasses}>{children}</div>
		</div>
	);
}
