import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import {BUGSNAG_PUBLIC} from '@halp/foundation/env';

Bugsnag.start({
	apiKey: BUGSNAG_PUBLIC,
	releaseStage: process.env.NODE_ENV,
	enabledReleaseStages: ['production', 'staging'],
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	plugins: [new BugsnagPluginReact(React as any)],
});

export default Bugsnag;
