import {useState, useEffect, useRef} from 'react';
import {Command} from 'cmdk';
import {faArrowLeftLong} from '@fortawesome/free-solid-svg-icons';
import {Icon, Stack} from '@halp/ui';
import {useI18n} from '../../i18n';
import {ProgramForm} from '../../Programs/ProgramForm';
import {Home} from './Home';
import {SearchStudents} from './SearchStudents';
import {SearchPrograms} from './SearchPrograms';
import style from './CommandPalette.module.css';

export type Page = 'searchStudents' | 'searchPrograms' | 'addProgram';

const PLACEHOLDERS = {
	searchStudents: 'command_palette.input_placeholder.search_students',
	searchPrograms: 'command_palette.input_placeholder.search_programs',
};

export function CommandPalette() {
	const {t} = useI18n();

	const [open, setOpen] = useState<boolean>(false);
	const [page, setPage] = useState<Page | null>(null);
	const [input, setInput] = useState<string>('');
	const ref = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		function handleKeyDown(e: KeyboardEvent) {
			if ((e.metaKey && e.key === 'k') || (e.ctrlKey && e.key === 'k')) {
				e.preventDefault();
				e.stopPropagation();

				setOpen((currentValue) => {
					return !currentValue;
				});
			}
		}

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	function handleSelectPage(page: Page) {
		setPage(page);
		setInput('');
	}

	function handleSelect() {
		setInput('');
		setOpen(false);
	}

	function popPage() {
		setPage(null);
	}

	return (
		<Command.Dialog
			ref={ref}
			open={open}
			onOpenChange={(value) => {
				if (!value) {
					setPage(null);
				}

				setOpen(value);
			}}
			label="Global Command Menu"
			onKeyDown={(e: React.KeyboardEvent) => {
				if (page === null || input.length) {
					return;
				}

				if (
					e.key === 'Backspace' ||
					(e.key === 'Escape' && e.metaKey) ||
					(e.key === 'Escape' && e.ctrlKey)
				) {
					e.preventDefault();
					popPage();
				}

				if (e.key === 'Enter') {
					setInput('');
				}
			}}
			className={style.Command}
		>
			<Stack
				justifyContent="space-between"
				fullWidth
				className={style.InputContainer}
			>
				{page ? (
					<div onClick={() => popPage()} className={style.ShortcutHints}>
						<Icon icon={faArrowLeftLong} />
					</div>
				) : null}
				{!(page === 'addProgram') && (
					<Command.Input
						className={style.Input}
						placeholder={
							page
								? t(PLACEHOLDERS[page])
								: t('command_palette.input_placeholder.default')
						}
						onValueChange={(value) => setInput(value)}
					/>
				)}
				<div onClick={() => setOpen(false)} className={style.ShortcutHints}>
					ESC
				</div>
			</Stack>
			<Command.List className={style.List}>
				{!(page === 'addProgram') && (
					<Command.Empty>{t('command_palette.empty')}</Command.Empty>
				)}
				{!page && (
					<Home onPageSelect={handleSelectPage} handleSelect={handleSelect} />
				)}
				{page === 'searchStudents' && (
					<SearchStudents search={input} closePalette={() => setOpen(false)} />
				)}
				{page === 'searchPrograms' && (
					<SearchPrograms search={input} onClose={() => setOpen(false)} />
				)}
				{page === 'addProgram' && (
					<ProgramForm onSubmit={() => setOpen(false)} />
				)}
			</Command.List>
		</Command.Dialog>
	);
}
