export function prettyPrint(string?: string | null) {
	return string
		?.replace(/_/g, ' ')
		.replace(/[^a-zA-Z0-9 ]/g, '')
		.replace(/\s+/g, ' ')
		.trim()
		.toLowerCase()
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
}
