import classNames from 'classnames';
import {
	type ReactNode,
	type ChangeEvent,
	useEffect,
	forwardRef,
	type ForwardedRef,
} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {Paragraph} from '@halp/ui';
import {useError} from '../hooks';
import {FormErrorMessage} from './FormErrorMessage';
import style from './FormField.module.css';
import type {RegisterOptions} from 'react-hook-form';

export interface BaseInputProps {
	name: string;
	disabled?: boolean;
	label?: ReactNode;
	rules?: RegisterOptions;
	placeholder?: string;
	value?: string | number | boolean | null;
	optional?: boolean;
	autoFocus?: boolean;
	onChange?: (
		event: ChangeEvent<
			HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
		>,
	) => void;
	className?: string;
	inputClassName?: string;
	labelClassName?: string;
}

interface InputProps extends BaseInputProps {
	type?: 'text' | 'checkbox' | 'password' | 'email' | 'tel';
	children?: null;
}

interface DropdownProps extends BaseInputProps {
	type: 'dropdown';
	value?: string;
	dropdownOptions?: string[];
	multiple?: boolean;
	children?: null;
}

interface TextAreaProps extends BaseInputProps {
	type: 'textarea';
	value?: string;
	maxLength?: number;
	height?: number;
	children?: null;
}

interface FloatProps extends BaseInputProps {
	type: 'float';
	value?: number;
	min?: number;
	max?: number;
	step?: number;
	children?: null;
}

interface NumberProps extends BaseInputProps {
	type: 'number';
	value?: number;
	min?: number;
	max?: number;
	step?: number;
	children?: null;
}

interface CheckboxProps extends BaseInputProps {
	type: 'checkbox';
	value?: boolean;
	children?: null;
	onCheck?: (checked: boolean) => void;
}

interface ChildProps extends BaseInputProps {
	type?: never;
	children: ReactNode;
}

interface RadioProps extends BaseInputProps {
	type: 'radio';
	checked?: boolean;
	value: string | boolean;
	children?: null;
}

interface HiddenProps extends BaseInputProps {
	type: 'hidden';
	value: string | number | boolean;
	children?: null;
}

type Props =
	| InputProps
	| DropdownProps
	| TextAreaProps
	| FloatProps
	| NumberProps
	| CheckboxProps
	| RadioProps
	| HiddenProps
	| ChildProps;

const InnerFormField = forwardRef(
	(props: Props, inputRef: ForwardedRef<HTMLElement>) => {
		const {
			name,
			rules: options,
			placeholder,
			value,
			disabled = false,
			onChange,
			inputClassName,
			autoFocus,
		} = props;
		const {register, control, setValue} = useFormContext();
		let rules = options ?? {};
		if (props.type === 'float' || props.type === 'number') {
			rules.valueAsNumber = true;
			rules.pattern = {
				value:
					props.type === 'float' ? /^(0|[1-9]\d*)(\.\d+)?$/ : /^(0|[1-9]\d*)?$/,
				message: 'Please enter a valid number',
			};
			if (rules.required && !rules.validate) {
				rules.validate = (value) => {
					if (Number.isNaN(value)) {
						return typeof rules.required === 'string' ? rules.required : false;
					}
					return true;
				};
			}
		}

		const {
			ref: formRef,
			onChange: registerOnChange,
			...rest
		} = register(name, rules);
		const ref = <T extends HTMLElement | null>(e: T) => {
			formRef(e);
			if (typeof inputRef === 'function') {
				inputRef(e);
			} else if (inputRef) {
				inputRef.current = e;
			}
		};
		useEffect(() => {
			if (value != null && props.type === 'hidden') {
				setValue(name, value, {shouldDirty: true, shouldValidate: true});
			}
		}, [value, props.type, name, setValue]);

		if (props.type === 'checkbox') {
			return (
				<Controller
					name={name}
					control={control}
					rules={rules}
					render={({
						field: {ref: formRef, onChange: onControllerChange, onBlur, value},
					}) => {
						return (
							<input
								autoFocus={autoFocus}
								type="checkbox"
								ref={<T extends HTMLInputElement | null>(e: T) => {
									formRef(e);
									if (typeof inputRef === 'function') {
										inputRef(e);
									} else if (inputRef) {
										inputRef.current = e;
									}
								}}
								disabled={disabled}
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									onControllerChange(e);
									if (onChange) {
										onChange(e);
									}
								}}
								onBlur={onBlur}
								checked={value}
								className={inputClassName}
							/>
						);
					}}
				/>
			);
		} else if (props.type === 'textarea') {
			const style = props.height ? {height: `${props.height}px`} : undefined;
			return (
				<Controller
					name={name}
					control={control}
					rules={rules}
					render={({field: {onChange: onControllerChange, onBlur, value}}) => {
						return (
							<textarea
								{...rest}
								ref={ref}
								placeholder={placeholder}
								disabled={disabled}
								maxLength={props.maxLength}
								style={style}
								onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
									onControllerChange(e);
									if (onChange) {
										onChange(e);
									}
								}}
								className={inputClassName}
								onBlur={onBlur}
								value={value}
							/>
						);
					}}
				/>
			);
		} else if (props.type === 'number') {
			return (
				<Controller
					name={name}
					control={control}
					rules={rules}
					render={({
						field: {
							ref: formRef,
							onChange: onControllerChange,
							onBlur,
							value,
							...rest
						},
					}) => {
						return (
							<input
								autoFocus={autoFocus}
								{...rest}
								type="number"
								ref={<T extends HTMLInputElement | null>(e: T) => {
									formRef(e);
									if (typeof inputRef === 'function') {
										inputRef(e);
									} else if (inputRef) {
										inputRef.current = e;
									}
								}}
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									onControllerChange(parseInt(e.target.value, 10));
									if (onChange) {
										onChange(e);
									}
								}}
								onBlur={onBlur}
								value={value}
								disabled={disabled}
								min={props.min}
								max={props.max}
								step={Number.parseInt(`${props.step ?? 1}`, 10)}
								placeholder={placeholder}
								className={inputClassName}
							/>
						);
					}}
				/>
			);
		} else if (props.type === 'float') {
			return (
				<Controller
					name={name}
					control={control}
					rules={rules}
					render={({
						field: {ref: formRef, onChange: onControllerChange, ...rest},
					}) => {
						return (
							<input
								autoFocus={autoFocus}
								type="number"
								ref={<T extends HTMLInputElement | null>(e: T) => {
									formRef(e);
									if (typeof inputRef === 'function') {
										inputRef(e);
									} else if (inputRef) {
										inputRef.current = e;
									}
								}}
								disabled={disabled}
								min={props.min}
								max={props.max}
								step={props.step ?? 0.1}
								placeholder={placeholder}
								className={inputClassName}
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									onControllerChange(parseFloat(e.target.value));
									if (onChange) {
										onChange(e);
									}
								}}
								{...rest}
							/>
						);
					}}
				/>
			);
		} else if (props.type === 'dropdown') {
			return (
				<select
					multiple={!!props.multiple}
					disabled={disabled}
					ref={ref}
					onChange={(e: ChangeEvent<HTMLSelectElement>) => {
						if (onChange) {
							onChange(e);
						}
					}}
					className={inputClassName}
				>
					{props.dropdownOptions?.map((option) => {
						return (
							<option key={option} value={option}>
								{option}
							</option>
						);
					})}
				</select>
			);
		} else if (props.type === 'radio') {
			const stringToBoolean = (value: string) => {
				if (value === 'true') {
					return true;
				} else if (value === 'false') {
					return false;
				}
				return value;
			};
			const performCast = props.value === true || props.value === false;
			return (
				<Controller
					name={name}
					rules={rules}
					render={({
						field: {
							ref: formRef,
							onBlur,
							onChange: onControllerChange,
							value: fieldValue,
						},
					}) => {
						return (
							<input
								autoFocus={autoFocus}
								name={name}
								type="radio"
								value={props.value != null ? `${props.value}` : undefined}
								onBlur={onBlur}
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									onControllerChange(
										performCast ? stringToBoolean(e.target.value) : e,
									);
									if (onChange) {
										onChange(e);
									}
								}}
								checked={
									props.checked ||
									(() => {
										if (performCast) {
											return `${fieldValue}`
												? fieldValue === props.value
												: false;
										}
										return fieldValue === props.value;
									})()
								}
								ref={<T extends HTMLInputElement | null>(e: T) => {
									formRef(e);
									if (typeof inputRef === 'function') {
										inputRef(e);
									} else if (inputRef) {
										inputRef.current = e;
									}
								}}
								disabled={disabled}
								placeholder={placeholder}
							/>
						);
					}}
				/>
			);
		}

		return (
			<input
				autoFocus={autoFocus}
				value={value != null ? `${value}` : undefined}
				ref={ref}
				type={props.type}
				disabled={disabled}
				placeholder={placeholder}
				className={inputClassName}
				onChange={(event) => {
					registerOnChange(event);
					if (onChange) {
						onChange(event);
					}
				}}
				{...rest}
			/>
		);
	},
);

export const FormField = forwardRef(
	(props: Props, ref: ForwardedRef<HTMLElement>) => {
		const {name, label, optional} = props;
		const errors = useError(name);

		const labelMarkup = label ? (
			<Paragraph
				className={classNames(style.FormFieldLabel, props.labelClassName)}
			>
				{label + (optional ? ' (optional)' : '')}
			</Paragraph>
		) : null;

		const errorMessages = errors?.map((err, index) => (
			<FormErrorMessage key={index}>{err.message}</FormErrorMessage>
		));

		const field =
			props.children != null ? (
				props.children
			) : (
				<InnerFormField {...props} ref={ref} />
			);

		return (
			<div
				className={classNames(
					style.FormField,
					props.type === 'checkbox' && style.Checkbox,
					props.className,
				)}
			>
				{labelMarkup}
				{field}
				{errorMessages}
			</div>
		);
	},
);
