import {Paragraph} from '@halp/ui';
import type {ReactNode} from 'react';

export function FormErrorMessage({children}: {children?: ReactNode}) {
	return (
		<Paragraph spacing="sm" color="red">
			{children}
		</Paragraph>
	);
}
