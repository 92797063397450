import type {ProgressStep} from '@halp/util/constants';
import {Domains} from './domains';
import {Path} from './path';

export const STUDENT_PATHS = {
	dashboard: {
		stage: new Path((stage: ProgressStep) => {
			const urlStage = stage.toLowerCase().replace('_', '-');
			return new Path(`/dashboard/${urlStage}`, Domains.go).url();
		}, Domains.go),
		application: new Path(
			(id: string) =>
				new Path(`/dashboard/applications/${id}`, Domains.go).url(),
			Domains.go,
		),
		applicationProfile: new Path((stage: ProgressStep) => {
			const urlStage = stage.toLowerCase().replace('_', '-');
			return new Path(
				`/dashboard/${urlStage}#application-profile`,
				Domains.go,
			).url();
		}, Domains.go),
	},
	documents: new Path('/documents', Domains.go),
	qualification: new Path('/qualification', Domains.go),
	applications: new Path('/applications', Domains.go),
	programs: new Path('/programs', Domains.go),
	survey: {
		archivedUnresponsive: new Path('/survey/2', Domains.go),
		recommendationRanking: new Path('/survey/recommendation', Domains.go),
	},
};
