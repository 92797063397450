import {useFormContext, useFormState} from 'react-hook-form';
import type {ButtonProps} from '@halp/ui';
import {Button} from '@halp/ui';

type Props = ButtonProps & {trigger?: boolean};

export function FormButton({
	trigger: shouldTrigger = true,
	onClick,
	disabled: isDisabled,
	...props
}: Props) {
	const {trigger} = useFormContext();
	// used for some trickery after await trigger
	const formState = useFormState();
	const {isSubmitting, isSubmitSuccessful, errors} = formState;

	const disabled =
		isDisabled || ((isSubmitting || isSubmitSuccessful) && !errors.server);

	const handleClick = async () => {
		if (shouldTrigger) {
			await trigger();
			const hasErrors = Object.keys(errors).length > 0;

			if (onClick && !hasErrors) {
				onClick();
			}
		} else if (onClick) {
			onClick();
		}
	};

	if (props.icon) {
		return (
			<Button
				disabled={disabled}
				type="submit"
				onClick={handleClick}
				{...props}
			/>
		);
	}

	return (
		<Button
			thin={!props.padding || props?.padding === 'sm'}
			disabled={disabled}
			type="submit"
			onClick={handleClick}
			{...props}
		/>
	);
}
