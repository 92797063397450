import {Stack} from '../../Stack';
import style from './IntegerRangeSelector.module.css';

interface Bounds {
	label: string;
	setRange: (value: number) => void;
	absMin: number;
	absMax: number | null;
	value: number | null;
}

function BoundInput({
	bounds,
	prefix,
	suffix,
}: {
	bounds: Bounds;
	prefix?: string;
	suffix?: string;
}) {
	const {label, value, setRange, absMin, absMax} = bounds;
	return (
		<Stack className={style.RangeContainer}>
			<label className={style.RangeLabel}>{label + ' ' + prefix}</label>
			<input
				type="number"
				className={style.RangeInput}
				value={value ?? undefined}
				onChange={(value) => setRange(value.target.valueAsNumber)}
				min={absMin}
				max={absMax ?? undefined}
				role="bound-input"
			/>
			<span className={style.RangeUnitLabel}>{suffix}</span>
		</Stack>
	);
}

interface Props {
	prefix: string;
	suffix: string;
	minBoundInput: Bounds;
	maxBoundInput: Bounds;
}

export function IntegerRangeSelector({
	prefix,
	suffix,
	minBoundInput,
	maxBoundInput,
}: Props) {
	return (
		<Stack>
			<BoundInput prefix={prefix} suffix={suffix} bounds={minBoundInput} />
			<span>-</span>
			<BoundInput prefix={prefix} suffix={suffix} bounds={maxBoundInput} />
		</Stack>
	);
}
