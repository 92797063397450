import {
	type ReactNode,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
	createContext,
} from 'react';
import Cookies from 'universal-cookie';
import {useToast} from '@halp/ui';
import {useGraphQL} from '@halp/api/graphql';
import {useQueryClient} from '@halp/api';
import {SESSION_DOMAIN} from '../../env';

const NODE_ENV = process?.env.NODE_ENV ?? 'development';
const COOKIE_DOMAIN =
	NODE_ENV === 'development' ? '.halp.localhost' : '.halp.co';

interface AuthTokens {
	token?: string | null;
	renewToken?: string | null;
}

interface Props {
	children: ReactNode;
	logoutMessage: string;
}

export interface Session {
	hasAuthToken: boolean;
	hasRenewToken: boolean;
	logout: VoidFunction;
	assignTokens: (tokens: AuthTokens) => void;
	refreshSession: () => Promise<boolean>;
}

export const SessionContext = createContext<Session | null>(null);

export function SessionProvider({children, logoutMessage}: Props) {
	const addToast = useToast();
	const cookies = useMemo(() => new Cookies(), []);
	const fetching = useRef(false);
	const queryClient = useQueryClient();
	const {client: graphQL} = useGraphQL();

	const [hasAuthToken, setHasAuthToken] = useState(
		!!cookies.get('halp_auth_token'),
	);

	const [hasRenewToken, setHasRenewToken] = useState(
		!!cookies.get('halp_renew_token'),
	);

	const assignTokens = useCallback(
		({token, renewToken}: AuthTokens) => {
			let hasAuthToken = false;
			let hasRenewToken = false;
			if (token) {
				cookies.set('halp_auth_token', token, {
					path: '/',
					sameSite: 'lax',
					domain: COOKIE_DOMAIN,
				});
				hasAuthToken = true;
			} else {
				cookies.remove('halp_auth_token', {
					path: '/',
					domain: COOKIE_DOMAIN,
				});
			}
			if (renewToken) {
				cookies.set('halp_renew_token', renewToken, {
					path: '/',
					sameSite: 'strict',
					domain: COOKIE_DOMAIN,
				});
				hasRenewToken = true;
			} else {
				cookies.remove('halp_renew_token', {
					path: '/',
					domain: COOKIE_DOMAIN,
				});
			}
			setHasAuthToken(hasAuthToken);
			setHasRenewToken(hasRenewToken);
		},
		[setHasAuthToken, cookies],
	);

	const logout = useCallback(() => {
		assignTokens({token: null, renewToken: null});
		addToast({type: 'error', message: logoutMessage});
		queryClient.clear();
	}, [assignTokens, addToast, logoutMessage, queryClient]);

	const refreshSession = useCallback(async () => {
		if (!hasRenewToken || fetching.current) {
			return false;
		}

		fetching.current = true;
		const response = await fetch(`${SESSION_DOMAIN}/renew`, {
			credentials: 'include',
			method: 'POST',
		});
		if (response.status === 401 || !response.ok) {
			logout();
			fetching.current = false;
			return false;
		}

		const json = await response.json();
		assignTokens({
			renewToken: json.data.renew_token,
			token: json.data.token,
		});
		fetching.current = false;
		return true;
	}, [assignTokens, hasRenewToken, logout]);

	const session = useMemo(() => {
		return {hasAuthToken, hasRenewToken, assignTokens, refreshSession, logout};
	}, [hasAuthToken, assignTokens, refreshSession, logout, hasRenewToken]);

	useEffect(() => {
		graphQL.authCallback = session.refreshSession;
	}, [session, graphQL]);

	return (
		<SessionContext.Provider value={session}>
			{children}
		</SessionContext.Provider>
	);
}

export function useSession() {
	const session = useContext(SessionContext);
	if (!session) {
		throw new Error('[Session]: Called outside of the Session provider');
	}
	return session;
}
