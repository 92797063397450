import classNames from 'classnames';
import {CSSVariants} from '@halp/util';
import typographyStyle from './Typography.module.css';
import type {Spacing, Align, Color, TextWrap, TextOverflow} from './types';

export interface TypographyOptions {
	spacing?: Spacing;
	align?: Align;
	color?: Color;
	bold?: boolean;
	underline?: boolean;
	preserveFormat?: boolean;
	strikeThrough?: boolean;
	textWrap?: TextWrap;
	textOverflow?: TextOverflow;
	className?: string;
	title?: string;
}

export function TypographyStyles({
	spacing = 'none',
	align = 'left',
	color = 'black',
	bold = false,
	underline = false,
	preserveFormat = false,
	strikeThrough = false,
	textWrap = 'normal',
	textOverflow,
	className,
}: TypographyOptions) {
	return classNames(
		CSSVariants(typographyStyle, 'TypographyColor', color),
		CSSVariants(typographyStyle, 'TypographySpacing', spacing),
		CSSVariants(typographyStyle, 'TypographyAlign', align),
		CSSVariants(typographyStyle, 'TypographyTextWrap', textWrap),
		CSSVariants(typographyStyle, 'TypographyTextOverflow', textOverflow),
		bold && typographyStyle.TypographyBold,
		underline && typographyStyle.TypographyUnderline,
		preserveFormat && typographyStyle.TypographyPreserveFormat,
		strikeThrough && typographyStyle.TypographyStrikeThrough,
		className,
	);
}
