import {type PropsWithChildren, useMemo} from 'react';
import classNames from 'classnames';
import {CSSVariants} from '@halp/util';
import {useBreakpoint} from '../Breakpoint';
import style from './Grid.module.css';

interface GridProps {
	columns: number;
	className?: string;
	fullWidth?: boolean;
	gridGap?: 'sm' | 'md' | 'lg';
}

interface Props {
	columns?: number;
	className?: string;
	sm?: number;
	md?: number;
	lg?: number;
	xl?: number;
	fullWidth?: boolean;
	gridGap?: 'sm' | 'md' | 'lg';
}

function GridComponent({
	children,
	columns,
	fullWidth,
	className,
	gridGap = 'md',
}: PropsWithChildren<GridProps>) {
	return (
		<div
			className={classNames(
				style.Grid,
				fullWidth && style.FullWidth,
				className,
				CSSVariants(style, 'GridGap', gridGap),
			)}
			style={{gridTemplateColumns: `repeat(${columns}, 1fr)`}}
		>
			{children}
		</div>
	);
}

export function Grid({
	sm = 1,
	md = 1,
	lg = 2,
	xl = 3,
	columns,
	...props
}: PropsWithChildren<Props>) {
	const isMD = useBreakpoint({size: 'md'});
	const isLG = useBreakpoint({size: 'lg'});
	const isXL = useBreakpoint({min: 'lg'});

	const cols = useMemo(() => {
		if (columns) {
			return columns;
		}
		if (xl != null && isXL) {
			return xl;
		}
		if (lg != null && isLG) {
			return lg;
		}
		if (md != null && isMD) {
			return md;
		}
		return sm;
	}, [columns, sm, md, lg, xl, isMD, isLG, isXL]);

	return <GridComponent columns={cols} {...props} />;
}
