import dynamic from 'next/dynamic';
export {type Options} from 'react-select';
import classNames from 'classnames';
import style from './Select.module.css';
import type {StateManagerProps} from 'react-select/dist/declarations/src/stateManager';
import type StateManagedSelect from 'react-select';
import type {ControlProps, GroupBase, ValueContainerProps} from 'react-select';

const ReactSelect = dynamic(() => import('react-select'), {
	ssr: false,
	loading: () => <input />,
}) as StateManagedSelect;

export const AsyncSelect = dynamic(() => import('react-select/async'), {
	ssr: false,
	loading: () => <input />,
});

interface Props {
	wrap?: 'nowrap';
}

export function Select<
	Option,
	IsMulti extends boolean = false,
	Group extends GroupBase<Option> = GroupBase<Option>,
>({
	wrap,
	classNames: cn,
	...props
}: StateManagerProps<Option, IsMulti, Group> & Props) {
	const selectClassName = classNames(style.Select, props.className);
	const control = (state: ControlProps<Option, IsMulti, Group>) =>
		classNames(wrap === 'nowrap' && style.NoWrap, cn?.control?.(state));
	const valueContainer = (state: ValueContainerProps<Option, IsMulti, Group>) =>
		classNames(wrap === 'nowrap' && style.NoWrap, cn?.valueContainer?.(state));

	return (
		<ReactSelect
			{...props}
			classNames={{
				...cn,
				control: control,
				valueContainer: valueContainer,
			}}
			className={selectClassName}
			classNamePrefix="form-select"
		/>
	);
}
