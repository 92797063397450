import classNames from 'classnames';
import {Dropdown} from '@halp/ui';
import style from './ProgramFilterDropdown.module.css';
import type {ReactNode} from 'react';

interface Props {
	useWithSlider?: boolean;
	children: ReactNode;
	toggle: string;
	caret?: boolean;
	onOutsideClick?: VoidFunction;
	closeOnOutsideClick?: boolean;
	onClick?: VoidFunction;
	wrapperClassName?: string;
	toggleClassName?: string;
	toggleBarClassName?: string;
}

export function ProgramFilterDropdown({
	useWithSlider = false,
	children,
	toggle,
	onOutsideClick,
	caret = true,
	closeOnOutsideClick = true,
	onClick,
	wrapperClassName,
	toggleClassName,
	toggleBarClassName,
}: Props) {
	return (
		<Dropdown
			toggle={toggle}
			onClick={onClick ? onClick : () => null}
			caret={caret}
			toggleClassName={classNames(
				useWithSlider ? style.SliderToggleDropdown : style.ToggleDropdown,
				toggleClassName,
			)}
			wrapperClassName={classNames(
				useWithSlider ? style.SliderWrapper : style.Wrapper,
				wrapperClassName,
			)}
			toggleBarClassName={classNames(
				useWithSlider ? style.SliderToggleBar : style.ToggleBar,
				toggleBarClassName,
			)}
			onOutsideClick={onOutsideClick}
			closeOnOutsideClick={closeOnOutsideClick}
		>
			{children}
		</Dropdown>
	);
}
