import {Command} from 'cmdk';
import {
	faBook,
	faChalkboardTeacher,
	faClipboardQuestion,
	faMagnifyingGlass,
	faPlusCircle,
	faUserGraduate,
} from '@fortawesome/free-solid-svg-icons';
import {useRouter} from '@halp/ui/router';
import {Paragraph} from '@halp/ui';
import {useI18n} from '../../i18n';
import {useUserType} from '../../Users';
import {Paths} from '../../Paths';
import style from './CommandPalette.module.css';
import {Item} from './Item';
import type {Page} from './CommandPalette';

interface Props {
	onPageSelect: (page: Page) => void;
	handleSelect: () => void;
}

export function Home({onPageSelect, handleSelect}: Props) {
	const {t} = useI18n();
	const userType = useUserType();
	const router = useRouter();

	const pageItems = [
		{
			label: t('command_palette.home.label.my_students'),
			onSelect: () => {
				router.push(Paths.coach.index.url());
				handleSelect();
			},
			icon: faChalkboardTeacher,
		},
		{
			label: t('command_palette.home.label.verified_students'),
			onSelect: () => {
				router.push(Paths.users.students.verified.url(userType));
				handleSelect();
			},
			icon: faUserGraduate,
		},
		{
			label: t('command_palette.home.label.programs'),
			onSelect: () => {
				router.push(Paths.programs.programs.index.url(userType));
				handleSelect();
			},
			icon: faBook,
		},
		{
			label: t('command_palette.home.label.handbook'),
			onSelect: () => {
				window.open(Paths.coach.handbook, '_blank');
				handleSelect();
			},
			icon: faClipboardQuestion,
		},
	];

	const actionItems = [
		{
			label: t('command_palette.home.label.search_students'),
			onSelect: () => onPageSelect('searchStudents'),
			icon: faMagnifyingGlass,
		},
		{
			label: t('command_palette.home.label.search_programs'),
			onSelect: () => onPageSelect('searchPrograms'),
			icon: faMagnifyingGlass,
		},
		{
			label: t('command_palette.home.label.add_program'),
			onSelect: () => onPageSelect('addProgram'),
			icon: faPlusCircle,
		},
	];

	return (
		<>
			<Command.Group
				heading={t('command_palette.home.heading.navigation')}
				className={style.GroupHeading}
			>
				{pageItems.map(({label, onSelect, icon}) => (
					<Item key={label} value={label} onSelect={onSelect} icon={icon}>
						<Paragraph>{label}</Paragraph>
					</Item>
				))}
			</Command.Group>
			<Command.Group
				heading={t('command_palette.home.heading.action')}
				className={style.GroupHeading}
			>
				{actionItems.map(({label, onSelect, icon}) => (
					<Item key={label} value={label} onSelect={onSelect} icon={icon}>
						<Paragraph>{label}</Paragraph>
					</Item>
				))}
			</Command.Group>
		</>
	);
}
