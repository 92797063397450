'use client';

import {useInsertionEffect} from 'react';

function generateID(path: string) {
	return path
		.toLowerCase()
		.trim()
		.replace(/[^\w\s-]/g, '')
		.replace(/[\s_-]+/g, '-')
		.replace(/^-+|-+$/g, '');
}

export function useAsyncCss(path: string, cleanup = false) {
	const id = generateID(path);

	useInsertionEffect(() => {
		const existingLink = document.getElementById(id);
		if (!existingLink) {
			const link: HTMLLinkElement = document.createElement('link');
			link.rel = 'stylesheet';
			link.href = path;
			link.id = id;
			document.head.appendChild(link);
		}

		return () => {
			if (cleanup) {
				const linkElm = document.getElementById(id);
				if (linkElm) {
					linkElm.remove();
				}
			}
		};
	});
	return null;
}
