import {useTranslations} from 'next-intl';
import {useMemo} from 'react';

export function useI18n() {
	const t = useTranslations();
	return useMemo(
		() => ({
			t,
		}),
		[t],
	);
}
