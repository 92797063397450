/* eslint-disable @typescript-eslint/naming-convention */
export const MARKETING_PATHS = {
	home: 'https://halp.co',
	about: 'https://halp.co/about',
	contact: 'https://halp.co/contact',
	faq: 'https://halp.co/faq',
	how_it_works: 'https://halp.co/how-it-works',
	privacy: 'https://halp.co/legal/privacy-policy',
	terms: 'https://halp.co/legal/terms-and-conditions',
};
