import {type ReactNode, useMemo} from 'react';
import {
	useQueryClient as useReactQueryClient,
	QueryClient,
	QueryClientProvider,
	HydrationBoundary,
} from '@tanstack/react-query';
import {GraphQLClient} from 'graphql-request';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {GRAPHQL_DOMAIN} from './env';
import {GraphQLClientContext} from './graphql';

interface Props {
	dehydratedState: unknown;
	authCallback?: () => Promise<boolean>;
	onError: (error: unknown) => void;
	children: ReactNode;
}

export function useQueryClient() {
	return useReactQueryClient();
}

export function ApiClientProvider({onError, dehydratedState, children}: Props) {
	const queryClient = useMemo(
		() =>
			new QueryClient({
				defaultOptions: {
					queries: {
						refetchOnWindowFocus: false,
					},
				},
			}),
		[],
	);

	const graphQLClient = useMemo(
		() => new GraphQLClient(GRAPHQL_DOMAIN, {credentials: 'include'}),
		[],
	);

	const clientValue = useMemo(() => {
		return {client: graphQLClient, errorCallback: onError};
	}, [onError, graphQLClient]);

	return (
		<GraphQLClientContext.Provider value={clientValue}>
			<QueryClientProvider client={queryClient}>
				<HydrationBoundary state={dehydratedState}>
					<ReactQueryDevtools initialIsOpen={false} />
					{children}
				</HydrationBoundary>
			</QueryClientProvider>
		</GraphQLClientContext.Provider>
	);
}
