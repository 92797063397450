import classNames from 'classnames';
import {CSSVariants} from '@halp/util';
import {useAnimation} from '../use-animation';
import style from './NotificationBadge.module.css';

export interface Props {
	count: number;
	position?: 'absolute' | 'relative';
}

export function NotificationBadge({count, position = 'absolute'}: Props) {
	const {className, ...animation} = useAnimation({
		animation: 'shake-y',
		duration: 3,
		direction: 'alternate',
		delay: 0.5,
	});

	const classes = classNames(
		style.NotificationBadge,
		className,
		CSSVariants(style, 'NotificationBadge', position),
	);

	return (
		<span className={classes} {...animation}>
			{count}
		</span>
	);
}
