import {useEffect, useState} from 'react';
import {IntegerRangeSlider} from '@halp/ui';
import type {IntegerRange} from '@halp/ui';
import {useI18n} from '../../../i18n';
import {ProgramFilterDropdown} from '../../ProgramFilterDropdown';

interface Props {
	onChange: (value: IntegerRange) => void;
	value?: IntegerRange;
}

export function TuitionRangeSelector({value, onChange}: Props) {
	const {t} = useI18n();
	const [lowerBound, setLowerBound] = useState<number>(value?.min ?? 0);
	const [upperBound, setUpperBound] = useState<number | null>(
		value?.max ?? null,
	);

	useEffect(() => {
		setLowerBound(value?.min ?? 0);
		setUpperBound(value?.max ?? null);
	}, [value]);

	const handleChange = (min: number, max: number | null) => {
		setLowerBound(min * 1000);
		setUpperBound(max ? max * 1000 : null);
	};
	return (
		<ProgramFilterDropdown
			toggle={`${t('tuition_range_selector.title')} ${lowerBound / 1000}K - ${
				upperBound ? `${upperBound / 1000}K` : 'Inf'
			}`}
			onOutsideClick={() =>
				onChange({
					min: lowerBound,
					max: upperBound,
				})
			}
		>
			<IntegerRangeSlider
				value={{
					min: lowerBound / 1000,
					max: upperBound ? upperBound / 1000 : null,
				}}
				header={t('tuition_range_selector.header')}
				boundPrefix="$"
				boundSuffix="K"
				onChange={handleChange}
				onReset={() => {
					setLowerBound(0);
					setUpperBound(null);
					onChange({min: 0, max: null});
				}}
				bounds={{min: 0, max: 100}}
				minInputLabel={t('tuition_range_selector.min')}
				maxInputLabel={t('tuition_range_selector.max')}
			/>
		</ProgramFilterDropdown>
	);
}
