import {useState} from 'react';
import type {RangeStep} from '@halp/ui';
import {StepRangeSlider} from '@halp/ui';
import {useI18n} from '../../../i18n';
import {ProgramFilterDropdown} from '../../ProgramFilterDropdown';
import style from './GradeRangeSelector.module.css';

export interface FloatRange {
	min?: number;
	max?: number;
}

interface Props {
	studentGrade: number;
	minGrade?: number;
	maxGrade?: number;
	onChange: (lowerBound: number, upperBound: number) => void;
}

export function GradeRangeSelector({
	studentGrade,
	minGrade,
	maxGrade,
	onChange,
}: Props) {
	const {t} = useI18n();

	const STEPS: RangeStep<number>[] = [
		{label: '0', value: 0},
		{
			label: `${t('grade_range_selector.safety')} ${studentGrade - 15}`,
			value: studentGrade - 15,
		},
		{
			label: `${t('grade_range_selector.target')} ${studentGrade - 10}`,
			value: studentGrade - 10,
		},
		{
			label: `${t('grade_range_selector.grade')} ${studentGrade}`,
			value: studentGrade,
		},
		{
			label: `${t('grade_range_selector.reach')} ${studentGrade + 10}`,
			value: studentGrade + 10,
		},
		{label: '100', value: 100},
	];

	const defaultLowerBound = studentGrade - 10;
	const defaultUpperBound = studentGrade;

	const [lowerBound, setLowerBound] = useState<number>(
		minGrade ?? defaultLowerBound,
	);
	const [upperBound, setUpperBound] = useState<number>(
		maxGrade ?? defaultUpperBound,
	);

	const handleChange = (lowerBound: number, upperBound: number) => {
		setLowerBound(lowerBound);
		setUpperBound(upperBound);
		onChange?.(lowerBound, upperBound);
	};

	const lowerBoundIndex = STEPS.findIndex((step) => step.value === lowerBound);
	const upperBoundIndex = STEPS.findIndex((step) => step.value === upperBound);

	return (
		<ProgramFilterDropdown
			useWithSlider
			toggle={t('grade_range_selector.title')}
			caret
			wrapperClassName={style.Wrapper}
			onOutsideClick={() => onChange(lowerBound, upperBound)}
			closeOnOutsideClick
		>
			<StepRangeSlider<number>
				header={t('grade_range_selector.inner_title')}
				steps={STEPS}
				lowerBoundIndex={lowerBoundIndex}
				upperBoundIndex={upperBoundIndex}
				onChange={handleChange}
				markClassName={style.Mark}
				headerSpacing="xs"
				onReset={() => {
					setLowerBound(minGrade ?? defaultLowerBound);
					setUpperBound(maxGrade ?? defaultUpperBound);
					onChange(
						minGrade ?? defaultLowerBound,
						maxGrade ?? defaultUpperBound,
					);
				}}
			/>
		</ProgramFilterDropdown>
	);
}
