interface Style {
	readonly [key: string]: string;
}

function kebabToPascale(kebab: string) {
	return kebab
		.split('-')
		.map((str) => str.charAt(0).toUpperCase() + str.slice(1))
		.join('');
}

export function CSSVariants(
	style: Style,
	prefix: string,
	variant?: boolean | string | number | null,
) {
	const pref = prefix ? `${prefix}-` : '';
	if (variant === true) {
		return style[kebabToPascale(pref)];
	}
	return variant ? style[kebabToPascale(`${pref}${variant}`)] : null;
}
