import {NextIntlClientProvider} from 'next-intl';
import type {IntlError} from 'next-intl';
import type {ComponentProps, ReactNode} from 'react';

const NODE_ENV = process?.env.NODE_ENV ?? 'development';

interface Props {
	locale: ComponentProps<typeof NextIntlClientProvider>['locale'];
	lngDict: ComponentProps<typeof NextIntlClientProvider>['messages'];
	onError: (error: IntlError) => void;
	children: ReactNode;
}

export function I18nProvider({
	locale,
	children,
	lngDict,
	onError: onErrorCallback,
}: Props) {
	function onError(error: IntlError) {
		onErrorCallback(error);

		if (NODE_ENV === 'development') {
			// eslint-disable-next-line no-console
			throw error;
		}
	}

	function getMessageFallback() {
		return '';
	}

	return (
		<NextIntlClientProvider
			locale={locale}
			timeZone="America/Toronto"
			messages={lngDict}
			onError={onError}
			getMessageFallback={getMessageFallback}
		>
			{children}
		</NextIntlClientProvider>
	);
}
