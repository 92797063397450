import {createContext, useContext, useMemo} from 'react';
import {useQuery} from '@halp/api/graphql';
import {UserOverviewDocument} from '../Student/UserOverview.query';
import type {ReactNode} from 'react';
import type {User} from '../Student/types';

interface SelectedUser {
	isLoading: boolean;
	user: User | null;
}

const SelectedUserContext = createContext<SelectedUser>({
	isLoading: true,
	user: null,
});

interface Props {
	userId?: string;
	children: ReactNode;
}

export function SelectedUserProvider({userId, children}: Props) {
	const {data, isLoading} = useQuery(UserOverviewDocument, {
		enabled: !!userId,
		variables: {id: userId!},
	});

	const user = useMemo(() => {
		return {
			isLoading: isLoading,
			user: data?.user ?? null,
		};
	}, [data, isLoading]);

	return (
		<SelectedUserContext.Provider value={user}>
			{children}
		</SelectedUserContext.Provider>
	);
}

export function useSelectedUser() {
	return useContext(SelectedUserContext);
}
