import {useRouter} from '@halp/ui/router';
export function usePushQueryParams() {
	const router = useRouter();
	return (queryParams: Record<string, string | undefined>) => {
		for (const key in queryParams) {
			if (queryParams[key]) {
				router.query[key] = queryParams[key];
			} else if (queryParams[key] === undefined) {
				delete router.query[key];
			}
		}
		router.push({
			query: {...router.query},
		});
	};
}
