import {
	type ReactNode,
	type AnchorHTMLAttributes,
	type ForwardedRef,
	forwardRef,
} from 'react';
import {default as NextLink, type LinkProps} from 'next/link';
import classNames from 'classnames';
import {CSSVariants} from '@halp/util';
import {useRouter} from '../router';
import style from './Link.module.css';
import type {UrlObject} from 'url';

export interface Props {
	color?: 'primary' | 'destructive';
	href?: string | UrlObject;
	as?: LinkProps['as'];
	nav?: boolean;
	activeClassName?: string;
	className?: string;
	children?: ReactNode;
	target?: AnchorHTMLAttributes<HTMLLinkElement>['target'];
	replace?: boolean;
	onClick?: (...any: unknown[]) => void;
	disabled?: boolean;
}

export const Link = forwardRef(
	(
		{
			color,
			href,
			as,
			nav,
			activeClassName,
			className,
			replace,
			disabled,
			...props
		}: Props,
		ref: ForwardedRef<HTMLAnchorElement>,
	) => {
		const router = useRouter();
		const activeClass = nav ? style.Active : activeClassName;
		const active =
			(activeClass != null &&
				typeof href === 'string' &&
				router?.asPath === href) ||
			(typeof href === 'object' && router?.asPath === href.pathname);
		const classes = classNames(
			CSSVariants(style, 'LinkColor', color),
			className,
			active && activeClass,
			disabled && style.Disabled,
		);

		const isExternalHref =
			!href ||
			(typeof href === 'string' &&
				(NextLink == null || /^https?:\/\//.test(href)));

		if (isExternalHref) {
			return (
				<a role="link" className={classes} ref={ref} href={href} {...props} />
			);
		}

		return (
			<NextLink href={href} as={as} replace={replace} legacyBehavior>
				<a role="link" className={classes} ref={ref} {...props} />
			</NextLink>
		);
	},
);
