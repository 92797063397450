import classNames from 'classnames';
import {CSSVariants} from '@halp/util';
import {TypographyStyles} from '../TypographyStyles';
import style from './Paragraph.module.css';
import type {TypographyOptions} from '../TypographyStyles';
import type {ReactNode} from 'react';

type ParagraphType = 'caption' | 'subtitle' | 'subtitle2' | 'body' | 'body2';

export interface ParagraphBase extends TypographyOptions {
	children: ReactNode;
	type?: ParagraphType;
}

interface ParagraphProps extends ParagraphBase {
	type?: 'caption' | 'body' | 'body2';
}

interface Subtitle1Props extends ParagraphBase {
	type: 'subtitle';
	weight?: 'medium' | 'semibold';
}

interface Subtitle2Props extends ParagraphBase {
	type: 'subtitle2';
	weight?: 'medium' | 'semibold';
}

export type Props = ParagraphProps | Subtitle1Props | Subtitle2Props;

export function Paragraph(props: Props) {
	const {children, title, ...typography} = props;
	const typographyStyles = TypographyStyles(typography);

	if (props.type === 'subtitle') {
		return (
			<p
				title={title}
				className={classNames(
					style.ParagraphTypeSubtitle,
					props.weight && CSSVariants(style, 'SubtitleWeight', props.weight),
					typographyStyles,
				)}
			>
				{children}
			</p>
		);
	} else if (props.type === 'subtitle2') {
		return (
			<p
				title={title}
				className={classNames(
					style.ParagraphTypeSubtitle2,
					props.weight && CSSVariants(style, 'Subtitle2Weight', props.weight),
					typographyStyles,
				)}
			>
				{children}
			</p>
		);
	}

	return (
		<p
			title={title}
			className={
				(CSSVariants(style, 'ParagraphType', props.type ?? 'body'),
				typographyStyles)
			}
		>
			{children}
		</p>
	);
}
