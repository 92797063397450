import type {
	IconDefinition,
	IconName,
	IconPrefix,
} from '@fortawesome/fontawesome-svg-core';

export const haClose: IconDefinition = {
	prefix: 'ha' as IconPrefix,
	iconName: 'close' as IconName,
	icon: [
		22,
		22,
		[],
		'',
		'M20.0666 1.93332L1.93332 20.0666 M1.93332 1.93332L20.0666 20.0666',
	],
};
