import {useQuery as useReactQuery} from '@tanstack/react-query';
import {useGraphQLRequest} from './client';
import type {
	QueryKey,
	UseQueryOptions as UseReactQueryOptions,
} from '@tanstack/react-query';
import type {TypedDocumentNode} from '@graphql-typed-document-node/core';

export interface UseQueryOptions<Result, Variables>
	extends Omit<UseReactQueryOptions<Result>, 'refetchInterval' | 'queryKey'> {
	variables?: Variables;
}

export function useQuery<Result, Variables>(
	document: TypedDocumentNode<Result, Variables>,
	options?: UseQueryOptions<Result, Variables>,
) {
	const request = useGraphQLRequest();
	const variables = options?.variables ?? undefined;
	const queryKey = [{document, variables}] as QueryKey;
	const queryFn = async () => {
		return request(document, variables);
	};
	return useReactQuery({queryKey, queryFn, ...options});
}
