import {useEffect, useRef} from 'react';
import {isBrowser} from '@halp/util';
import type {DependencyList} from 'react';

interface Position {
	x: number;
	y: number;
}

function getScrollPosition(element?: HTMLElement | null): Position {
	if (element) {
		const pos = element.getBoundingClientRect();
		return {x: pos.left, y: pos.top};
	}

	return isBrowser() ? {x: window.scrollX, y: scrollY} : {x: 0, y: 0};
}

type Effect = (position: {previous: Position; current: Position}) => void;

export function useScrollPosition(
	effect: Effect,
	deps?: DependencyList,
	element?: HTMLElement | null,
	target?: HTMLElement | null,
) {
	const position = useRef(getScrollPosition(element));

	useEffect(() => {
		const eventTarget = target ?? element ?? window;
		const handleScroll = () => {
			const pos = getScrollPosition(element);
			effect({previous: position.current, current: pos});
			position.current = pos;
		};

		eventTarget.addEventListener('scroll', handleScroll);

		return () => eventTarget.removeEventListener('scroll', handleScroll);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);
}
