import classNames from 'classnames';
import {CSSVariants} from '@halp/util';
import {TypographyStyles} from '../TypographyStyles';
import style from './Heading.module.css';
import type {TypographyOptions} from '../TypographyStyles';
import type {ReactNode} from 'react';

export type HeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface Props extends TypographyOptions {
	children: ReactNode;
	type?: HeadingType;
	weight?: 'medium' | 'semibold' | 'bold';
}

export function Heading({children, type, weight, title, ...typography}: Props) {
	const typographyStyles = TypographyStyles(typography);

	const classes = classNames(
		CSSVariants(style, 'Heading', type),
		CSSVariants(style, 'Weight', weight),
		typographyStyles,
	);

	if (type === 'h1') {
		return (
			<h1 title={title} className={classes}>
				{children}
			</h1>
		);
	} else if (type === 'h2') {
		return (
			<h2 title={title} className={classes}>
				{children}
			</h2>
		);
	} else if (type === 'h3') {
		return (
			<h3 title={title} className={classes}>
				{children}
			</h3>
		);
	} else if (type === 'h4') {
		return (
			<h4 title={title} className={classes}>
				{children}
			</h4>
		);
	} else if (type === 'h5') {
		return (
			<h5 title={title} className={classes}>
				{children}
			</h5>
		);
	} else if (type === 'h6') {
		return (
			<h6 title={title} className={classes}>
				{children}
			</h6>
		);
	}

	return (
		<label title={title} className={classes}>
			{children}
		</label>
	);
}
