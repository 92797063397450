export class ResponseError extends Error {
	public statusCode: number;
	public constructor({
		message = '',
		statusCode,
	}: {
		message: string;
		statusCode: number;
	}) {
		super(message);
		this.name = this.constructor.name;
		this.statusCode = statusCode;
	}
}
