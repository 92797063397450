import {createContext, useContext, useMemo} from 'react';

interface TableProviderLoading {
	data: null;
	isLoading: true;
}

interface TableProviderLoaded<T> {
	data: T[];
	isLoading: false;
}

type TableProviderContext<T> = TableProviderLoading | TableProviderLoaded<T>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TableContext = createContext<TableProviderContext<any>>({
	data: null,
	isLoading: true,
});

interface Props<T> {
	data: T[] | null;
	operationName?: string;
	isLoading: boolean;
	children: React.ReactNode;
}

export function QueryTableProvider<T>({
	isLoading,
	data,
	children,
	operationName,
}: Props<T>) {
	const value: TableProviderContext<T> = useMemo(() => {
		if (!isLoading && data) {
			return {data: data, isLoading: false};
		} else {
			return {data: null, isLoading: true};
		}
	}, [data, isLoading]);

	if (!isLoading && !data) {
		throw new Error(`Data ${operationName} is not loading`);
	} else {
		return (
			<TableContext.Provider value={value}>{children}</TableContext.Provider>
		);
	}
}

export function useQueryTable() {
	return useContext(TableContext);
}
