import type {CountryData} from './types';

export const PINNED_COUNTRIES = ['India', 'Pakistan', 'Nigeria', 'Ghana'];

export async function getCountries() {
	const data = await import('country-region-data/data.json');
	return data.default as CountryData[];
}

export async function getCountryNames() {
	const countries = await getCountries();
	return countries.map((country) => country.countryName);
}

export const ENGLISH_PROFICIENCY_TEST_EXPEMPTION_COUNTRIES = [
	'Anguilla',
	'Antigua and Barbuda',
	'Australia',
	'Bahamas',
	'Barbados',
	'Bermuda',
	'Belize',
	'Botswana',
	'Cayman Islands',
	'Channel Islands',
	'Dominica',
	'Fiji',
	'Gambia, The',
	'Ghana',
	'Gibraltar',
	'Grenada',
	'Guam',
	'Guyana',
	'Isle of Man',
	'Ireland',
	'Jamaica',
	'Kenya',
	'Kiribati',
	'Lesotho',
	'Liberia',
	'Malawi',
	'Malta',
	'Mauritius',
	'Namibia',
	'New Zealand',
	'Nigeria',
	'Pitcairn',
	'Puerto Rico',
	'Saint Helena, Ascension and Tristan da Cunha',
	'Saint Kitts and Nevis',
	'Saint Lucia',
	'Saint Vincent and the Grenadines',
	'Seychelles',
	'Sierra Leone',
	'Singapore',
	'South Africa',
	'Tanzania, The United Republic of',
	'Trinidad and Tobago',
	'Turks and Caicos Islands',
	'Tuvalu',
	'Uganda',
	'United Kingdom',
	'United States',
	'Virgin Islands, U.S.',
	'Zambia',
	'Zimbabwe',
];
