import {
	type ReactNode,
	type AnchorHTMLAttributes,
	useEffect,
	useState,
} from 'react';
import classNames from 'classnames';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {Icon} from '../Icon';
import style from './Chip.module.css';

export interface Props {
	children: ReactNode;
	initialToggled?: boolean;
	overrideToggled?: boolean;
	color?: 'primary' | 'accent';
	onClick?: AnchorHTMLAttributes<HTMLButtonElement>['onClick'];
}

export function Chip({
	children,
	color,
	onClick,
	initialToggled,
	overrideToggled,
}: Props) {
	const [toggled, setToggle] = useState<boolean>(initialToggled || false);

	useEffect(() => {
		if (overrideToggled !== undefined) {
			setToggle(overrideToggled);
		}
	}, [overrideToggled]);

	const toggledClass = toggled && style.ChipToggled;
	const colorClass =
		toggled && (color === 'accent' ? style.ChipAccent : style.ChipPrimary);

	const handleClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
	) => {
		setToggle(!toggled);
		if (onClick) {
			onClick(event);
		}
	};

	return (
		<button
			type="button"
			className={classNames(style.Chip, toggledClass, colorClass)}
			onClick={handleClick}
		>
			{children}
			<div className={style.Close}>
				<Icon icon={faTimes} />
			</div>
		</button>
	);
}
