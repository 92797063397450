import {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {useMutation, useQuery} from '@halp/api/graphql';
import {useChannel} from '@halp/api/channels';
import {useUser} from '../Users';
import {UserNotificationsDocument} from './UserNotifications.query';
import {DismissNotificationDocument} from './DismissNotification.mutation';
import {DismissAllNotificationsDocument} from './DismissAllNotifications.mutation';
import type {BroadcastNotification, Notification} from './types';
import type {ReactNode} from 'react';

const NOTIFICATION_TOPIC = 'notification';

export const NotificationContext = createContext<{
	notifications: Notification[];
	history: Notification[];
	dismiss: (id: string) => void;
	dismissAll: () => void;
} | null>(null);

export function NotificationProvider({children}: {children: ReactNode}) {
	const {user} = useUser();

	const {data} = useQuery(UserNotificationsDocument, {
		variables: {userId: user?.id || '', read: null},
		enabled: !!user,
	});

	const [dismissNotification] = useMutation(DismissNotificationDocument, {
		invalidateQueries: [
			{
				document: UserNotificationsDocument,
				variables: {
					userId: user?.id,
				},
			},
		],
		onSuccess: () => setBroadcasts([]),
	});

	const [dismissAllNotifications] = useMutation(
		DismissAllNotificationsDocument,
		{
			invalidateQueries: [
				{
					document: UserNotificationsDocument,
					variables: {
						userId: user?.id,
					},
				},
			],
			onSuccess: () => setBroadcasts([]),
		},
	);

	const [broadcasts, setBroadcasts] = useState<readonly Notification[]>([]);

	const notificationChannel = useChannel(`user_notifications:${user?.id}`);

	useEffect(() => {
		if (!notificationChannel) return;

		const channelRef = notificationChannel.on(
			NOTIFICATION_TOPIC,
			({
				// eslint-disable-next-line @typescript-eslint/naming-convention
				inserted_at,
				...response
			}: BroadcastNotification) => {
				setBroadcasts((prev) => [
					...prev,
					{
						...response,
						insertedAt: inserted_at,
						metadata: {
							studentUserId: response.metadata?.student_user_id,
							applicationId: response.metadata?.application_id,
						},
					},
				]);
			},
		);

		return () => {
			notificationChannel.off(NOTIFICATION_TOPIC, channelRef);
		};
	}, [notificationChannel]);

	const providerValue = useMemo(() => {
		const unreadNotifications =
			data?.listUserNotifications?.filter(
				(notification) => !notification.read,
			) ?? [];

		// use old school reverse because toReversed wasn't getting polyfilled
		const notifications = broadcasts
			.slice()
			.reverse()
			.concat(unreadNotifications);

		const history =
			data?.listUserNotifications?.filter(
				(notification) => notification.read,
			) ?? [];

		return {
			notifications,
			history,
			dismiss: (id: string) => dismissNotification({id: id}),
			dismissAll: () => dismissAllNotifications({userId: user?.id as string}),
		};
	}, [data, broadcasts, dismissNotification, dismissAllNotifications, user]);

	return (
		<NotificationContext.Provider value={providerValue}>
			{children}
		</NotificationContext.Provider>
	);
}

export function useNotifications() {
	const notifications = useContext(NotificationContext);
	if (!notifications) {
		throw new Error(
			'[Notifications]: Called outside of the Notification provider',
		);
	}
	return notifications;
}
