import classNames from 'classnames';
import {CSSVariants} from '@halp/util';
import style from './use-animation.module.css';

type Animation =
	| 'fade-in'
	| 'fade-out'
	| 'spin'
	| 'float'
	| 'pulse'
	| 'shake-x'
	| 'shake-y'
	| 'scale-out-and-in';

interface Props {
	animation: Animation;
	duration?: 1 | 2 | 3 | 4 | 5 | 6;
	delay?: number;
	infinite?: boolean;
	direction?: 'normal' | 'reverse' | 'alternate' | 'alternate-reverse';
}

export function useAnimation({
	animation,
	duration,
	delay,
	direction,
	infinite,
}: Props) {
	return {
		className: classNames(
			style.Animation,
			CSSVariants(style, 'Animation', animation),
			CSSVariants(style, 'AnimationDuration', `${duration}`),
			CSSVariants(style, 'AnimationInfinite', infinite),
			CSSVariants(style, 'AnimationDirection', direction),
		),
		style: {animationDelay: `${delay}s`},
	};
}
