import {useFormContext} from 'react-hook-form';
import classNames from 'classnames';
import {Stack} from '@halp/ui';
import {capitalize} from '@halp/util';
import {useI18n} from '../../i18n';
import {FormField} from './FormField';
import style from './FormField.module.css';
import type {BaseInputProps} from './FormField';

interface Period {
	year?: number | null;
	month?: number | null;
	week?: number | null;
	day?: number | null;
	second?: number | null;
}

interface DurationProps extends BaseInputProps {
	showYear?: boolean;
	showMonth?: boolean;
	showWeek?: boolean;
	showDay?: boolean;
	showSecond?: boolean;
	hidePeriodLabels?: boolean;
	defaultValue?: Period | null;
	className?: string;
	labelClassName?: string;
}

export function FormDurationField({
	disabled = false,
	label,
	placeholder,
	optional,
	name,
	rules,
	showYear = true,
	showMonth,
	showWeek,
	showDay,
	showSecond,
	hidePeriodLabels = false,
	defaultValue = null,
	onChange,
	className,
	labelClassName,
}: DurationProps) {
	const {t} = useI18n();
	const {watch, setValue} = useFormContext();
	const periods = [
		{period: 'year', show: showYear},
		{period: 'month', show: showMonth},
		{period: 'week', show: showWeek},
		{period: 'day', show: showDay},
		{period: 'second', show: showSecond},
	];

	return (
		<Stack direction="column" alignItems="flex-start" fullWidth>
			{label ? (
				<label className={style.FormFieldLabel}>
					{optional ? t('optional_label', {label: `${label}`}) : label}
				</label>
			) : null}
			<Stack spacing="xs" fullWidth>
				{periods.map(({period, show}) => {
					if (!show) return null;
					const fieldName = `${name}.${period}`;
					const value = watch(fieldName);

					if (value == null) {
						setValue(name, defaultValue);
					}

					return (
						<Stack
							direction="column"
							alignItems="baseline"
							spacing="xs"
							fullWidth
							key={period}
						>
							{!hidePeriodLabels ? capitalize(period ?? '') : null}
							<FormField
								type="number"
								min={0}
								onChange={onChange}
								name={fieldName}
								placeholder={placeholder}
								disabled={disabled}
								rules={rules}
								className={classNames(style.FormDurationInput, className)}
								labelClassName={labelClassName}
							/>
						</Stack>
					);
				})}
			</Stack>
		</Stack>
	);
}
