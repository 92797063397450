import {useState} from 'react';
import {Chip} from '@halp/ui/';
import {useFormContext, FormDurationField} from '../../Form';
import {useI18n} from '../../i18n';
import style from './ProgramForm.module.css';
import type {Program} from '../types';

interface ProgramLengthProps {
	program?: Program | null;
	requireMaxLength?: boolean;
}

export function ProgramLength({
	program,
	requireMaxLength = false,
}: ProgramLengthProps) {
	const {t} = useI18n();
	const {setValue} = useFormContext();
	const [showLengthInterval, setShowLengthInterval] = useState<boolean>(
		program?.minLength ? true : false,
	);

	return (
		<div className={style.ProgramLength}>
			<FormDurationField
				name="program.maxLength"
				label="Program Length"
				showYear
				showMonth
				showWeek
				defaultValue={{year: 0, month: 0, week: 0}}
				rules={
					requireMaxLength
						? {
								required: t('validation.mixed.required'),
							}
						: undefined
				}
			/>
			{showLengthInterval ? (
				<FormDurationField
					name="program.minLength"
					label="Min Length"
					showYear
					showMonth
					showWeek
					defaultValue={{year: 0, month: 0, week: 0}}
				/>
			) : null}
			<Chip
				initialToggled={showLengthInterval}
				onClick={() => {
					if (showLengthInterval) {
						setValue('program.minLength', null);
					}
					setShowLengthInterval((prevState) => !prevState);
				}}
			>
				{t('program.program_form.length_toggle')}
			</Chip>
		</div>
	);
}
