import {faCircle} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import ReactSlider from 'react-slider';
import {useState} from 'react';
import {CSSVariants} from '@halp/util';
import {Icon} from '../../Icon';
import {Stack} from '../../Stack';
import {Heading, Paragraph} from '../../Typography';
import {Surface} from '../../Surface';
import style from '../RangeSlider.module.css';
import {Link} from '../../Link';
import {IntegerRangeSelector} from './IntegerRangeSelector';

interface BoundRange {
	min: number;
	max: number;
}

export interface IntegerRange {
	min: number;
	max: number | null;
}
export interface Props {
	header: string;
	value?: IntegerRange;
	bounds: BoundRange;
	boundPrefix: string;
	boundSuffix: string;
	onChange?: (min: number, max: number | null) => void;
	onReset?: () => void;
	minInputLabel: string;
	maxInputLabel: string;
}

export function IntegerRangeSlider({
	header,
	value,
	bounds,
	boundPrefix,
	boundSuffix,
	minInputLabel,
	maxInputLabel,
	onChange,
	onReset,
}: Props) {
	const [lowerBound, setLower] = useState<number>(value?.min ?? bounds.min);
	const [upperBound, setUpper] = useState<number>(value?.max ?? bounds.max);

	const setLowerBound = (value: number) => {
		setLower(value);
		if (onChange) {
			onChange(value, upperBound);
		}
	};

	const setUpperBound = (value: number) => {
		setUpper(value);
		if (onChange) {
			onChange(lowerBound, value);
		}
	};

	return (
		<Stack
			direction="column"
			spacing="sm"
			fullWidth
			alignItems="stretch"
			justifyContent="center"
			className={style.RangeSlider}
		>
			<Stack justifyContent="space-between" fullWidth>
				<Heading type="h5" color="grey">
					{header}
				</Heading>
				<Link
					className={style.Reset}
					onClick={() => {
						onReset?.();
						setLowerBound(bounds.min);
						setUpperBound(bounds.max);
					}}
				>
					Reset
				</Link>
			</Stack>
			<Stack
				direction="column"
				fullWidth
				alignItems="center"
				spacing="none"
				justifyContent="center"
			>
				<Surface className={style.SliderContainer} shadow="none">
					<ReactSlider
						className={style.HorizontalSlider}
						min={bounds.min}
						max={bounds.max}
						renderTrack={(props, state) => (
							<div
								{...props}
								className={classNames(
									style.Track,
									CSSVariants(style, 'Track', state.index) ?? undefined,
								)}
							/>
						)}
						onChange={(value) => {
							setLowerBound(value[0]);
							setUpperBound(value[1]);
						}}
						value={[lowerBound, upperBound]}
						ariaLabel={['Lower thumb', 'Upper thumb']}
						ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
						renderThumb={(props, state) => (
							<div {...props} className={style.ThumbContainer}>
								<span>
									{state.valueNow}
									{boundSuffix}
								</span>
								<Icon icon={faCircle} className={style.Thumb} />
							</div>
						)}
						pearling
						minDistance={10}
						withTracks
						thumbClassName={style.Thumb}
					/>
				</Surface>
				<Stack
					justifyContent="space-between"
					fullWidth
					className={style.BottomRange}
				>
					<Paragraph color="grey">{bounds.min + boundSuffix}</Paragraph>
					<Paragraph color="grey">{bounds.max + boundSuffix}</Paragraph>
				</Stack>
			</Stack>
			<IntegerRangeSelector
				prefix={boundPrefix}
				suffix={boundSuffix}
				minBoundInput={{
					label: minInputLabel,
					absMin: bounds.min,
					absMax: upperBound,
					value: lowerBound,
					setRange: setLowerBound,
				}}
				maxBoundInput={{
					label: maxInputLabel,
					absMin: lowerBound,
					absMax: bounds.max,
					value: upperBound,
					setRange: setUpperBound,
				}}
			/>
		</Stack>
	);
}
