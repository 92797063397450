import {Controller, useWatch} from 'react-hook-form';
import {AsyncSelect} from '@halp/ui/Select';
import {useI18n} from '../../i18n';
import {FormField} from './FormField';
import type {RegisterOptions} from 'react-hook-form';
import type {ReactNode} from 'react';

export interface Option {
	label: string;
	value: string | number;
	metadata?: unknown | null;
}

interface Props {
	name: string;
	disabled?: boolean;
	label?: ReactNode;
	rules?: RegisterOptions;
	placeholder?: string;
	value?: string | number | boolean;
	optional?: boolean;
	isMulti?: boolean;
	maxSelections?: number;
	loadOptions: (search: string) => Promise<Option[]>;
	onChange?: (option: Option | Option[]) => void;
	defaultInputValue?: string;
}

export function FormAsyncSelect({
	name,
	label,
	rules,
	placeholder,
	optional,
	loadOptions,
	isMulti,
	maxSelections,
	onChange,
	defaultInputValue,
}: Props) {
	const {t} = useI18n();
	const values = useWatch({name}) ?? [];

	return (
		<FormField name={name} label={label} optional={optional}>
			<Controller
				name={name}
				rules={rules}
				render={({field: {onChange: onControllerChange, onBlur, value}}) => {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					const handleChange = (option: any) => {
						onChange?.(option);
						if (isMulti && Array.isArray(option)) {
							const updateValue = (option as Option[]).map(
								(item) => item.value,
							);
							return onControllerChange(updateValue);
						} else {
							return onControllerChange((option as Option | null)?.value);
						}
					};

					return (
						<AsyncSelect
							name={name}
							onChange={handleChange}
							placeholder={placeholder}
							onBlur={onBlur}
							isMulti={isMulti}
							defaultValue={value}
							defaultOptions
							defaultInputValue={defaultInputValue}
							loadOptions={
								maxSelections && values.length === maxSelections
									? undefined
									: loadOptions
							}
							noOptionsMessage={() => {
								return maxSelections && values.length === maxSelections
									? t('validation.multi_select.max')
									: t('validation.multi_select.no_options');
							}}
							backspaceRemovesValue={true}
							maxMenuHeight={100}
							styles={{
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								control: (provided: any) => ({
									...provided,
									border: 'solid 1px var(--base-color-lightest)',
									borderRadius: 'var(--border-radius-md)',
									color: 'var(--base-color-dark)',
									fontSize: '1rem',
									lineHeight: 1.38,
									padding: '0.4rem',
								}),
							}}
						/>
					);
				}}
			/>
		</FormField>
	);
}
