import {dayjs} from './dayjs';
import type {Dayjs} from 'dayjs';

export enum Format {
	DayLong = 'DD', // 03
	DayTitleShort = 'ddd', // Mon
	DayTitleLong = 'dddd', // Monday
	MonthTitleLong = 'MMMM', // January
	MonthTitleShort = 'MMM', // Jan
	MonthTitleYear = 'MMM, YYYY', // Jan, 2020
	MonthCompact = 'MM/DD', // 03/21
	DateCompact = 'YYYY-MM-DD', // 2020-01-16
	DateShort = 'MMMM Do, YYYY', // September 21st, 2020
	DateMonthShort = 'MMM Do, YYYY', // Sep 21st, 2020
	DateDayShort = 'dddd, MMMM Do', // Thursday, May 20th
	DateDayCompact = 'MMMM Do',
	DateLong = 'MMM Do, YYYY, h:mm A', // Sep 21st, 2020, 8:30 PM
	TimeShort = 'h:mm A', // 8:30 PM
	TimeLong = 'h:mm:ss A', // 8:30:00 PM
	TimeCompact = 'HH:mm', // 08:30
	TimeStandard = 'HH:mm:ss.SSS', // 08:30:00:000
	TimeStandardHour = 'HH:00:00.000', // 8:00:00:000
	DateTimeCompact = '', // ISO
	DateTimeLongAt = 'MMMM Do [at] h:mm A', //  January 1st at 8:00 PM
}

export function formatDateTime(
	dateTime: string | number | Date | Dayjs,
	format: keyof typeof Format,
	local = true,
) {
	if (local) {
		return dayjs.utc(dateTime).local().format(Format[format]);
	}

	return dayjs(dateTime).format(Format[format]);
}
