// English Proficiency Tests
export type AcceptedEnglishProficiencyTests =
	| 'IELTS'
	| 'TOEFL'
	| 'PTE'
	| 'DUOLINGO';

export const ACCEPTED_EP_TESTS: {
	[key in AcceptedEnglishProficiencyTests]: string;
} = {
	IELTS: 'english_test.ielts',
	TOEFL: 'english_test.toefl',
	PTE: 'english_test.pte',
	DUOLINGO: 'english_test.duolingo',
};

export type EnglishProficiencyTests =
	| 'IELTS'
	| 'TOEFL'
	| 'PTE'
	| 'DUOLINGO'
	| 'CAMBRIDGE'
	| 'OET';

export const ENGLISH_PROFICIENCY_TESTS: {
	[key in EnglishProficiencyTests]: string;
} = {
	IELTS: 'english_test.ielts',
	TOEFL: 'english_test.toefl',
	PTE: 'english_test.pte',
	DUOLINGO: 'english_test.duolingo',
	OET: 'english_test.oet',
	CAMBRIDGE: 'english_test.cambridge',
};

// Student Document Types
export type StudentDocumentTypes =
	| GeneralDocumentTypes
	| ApplicationDocumentTypes
	| OnshoreDocumentTypes;

export const STUDENT_DOCUMENT_TYPE_NAMES: {
	[type in StudentDocumentTypes]: string;
} = {
	PASSPORT: 'documents.types.passport',
	G10_TRANSCRIPT: 'documents.types.g10_transcript',
	G11_TRANSCRIPT: 'documents.types.g11_transcript',
	G12_TRANSCRIPT: 'documents.types.g12_transcript',
	BACHELORS_TRANSCRIPT: 'documents.types.bachelors_transcript',
	DIPLOMA_TRANSCRIPT: 'documents.types.diploma_transcript',
	G10_CERTIFICATE: 'documents.types.g10_certificate',
	G11_CERTIFICATE: 'documents.types.g11_certificate',
	HIGH_SCHOOL_GRADUATION_CERTIFICATE:
		'documents.types.high_school_graduation_certificate',
	BACHELORS_GRADUATION_CERTIFICATE:
		'documents.types.bachelors_graduation_certificate',
	IELTS: 'documents.types.ielts',
	TOEFL: 'documents.types.toefl',
	DUOLINGO: 'documents.types.duolingo',
	PTE: 'documents.types.pte',
	CAMBRIDGE: 'documents.types.cambridge',
	OET: 'documents.types.oet',
	FINANCIAL_DOCUMENTS: 'documents.types.financial_documents',
	PROOF_OF_NAME_CHANGE: 'documents.types.proof_of_name_change',
	ENGLISH_PROFICIENCY_TEST_EXEMPTION:
		'documents.types.english_proficiency_test_exemption',
	PROOF_OF_VACCINE: 'documents.types.proof_of_vaccine',
	STATEMENT_OF_PURPOSE: 'documents.types.statement_of_purpose',
	RESUME: 'documents.types.resume',
	STUDY_GAP_EXPLANATION: 'documents.types.study_gap_explanation',
	LETTER_OF_REFERENCE: 'documents.types.letter_of_reference',
	AUTHORIZATION_FORM: 'documents.types.authorization_form',
	DECLARATION_FORM: 'documents.types.declaration_form',
	PROGRAM_ESSAY: 'documents.types.program_essay',
	COMPLETE_APPLICATION_PACKAGE: 'documents.types.complete_application_package',
	LETTER_OF_ACCEPTANCE: 'documents.types.letter_of_acceptance',
	LETTER_OF_ENROLLMENT: 'documents.types.letter_of_enrollment',
	STUDY_PERMIT_LETTER: 'documents.types.study_permit_letter',
	STUDY_VISA: 'documents.types.study_visa',
	REJECTION_LETTER: 'documents.types.rejection_letter',
	WAITLIST: 'documents.types.waitlist',
	OTHER: 'documents.types.other',
};

export type GeneralDocumentTypes =
	| 'PASSPORT'
	| 'G10_TRANSCRIPT'
	| 'G11_TRANSCRIPT'
	| 'G12_TRANSCRIPT'
	| 'BACHELORS_TRANSCRIPT'
	| 'DIPLOMA_TRANSCRIPT'
	| 'G10_CERTIFICATE'
	| 'G11_CERTIFICATE'
	| 'HIGH_SCHOOL_GRADUATION_CERTIFICATE'
	| 'BACHELORS_GRADUATION_CERTIFICATE'
	| 'IELTS'
	| 'TOEFL'
	| 'DUOLINGO'
	| 'PTE'
	| 'CAMBRIDGE'
	| 'OET'
	| 'FINANCIAL_DOCUMENTS'
	| 'PROOF_OF_NAME_CHANGE'
	| 'RESUME'
	| 'ENGLISH_PROFICIENCY_TEST_EXEMPTION';

export const GENERAL_DOCUMENT_TYPES: {[type in GeneralDocumentTypes]: string} =
	{
		PASSPORT: 'Passport',
		G10_TRANSCRIPT: 'Grade 10 Transcript',
		G11_TRANSCRIPT: 'Grade 11 Transcript',
		G12_TRANSCRIPT: 'Grade 12 Transcript',
		BACHELORS_TRANSCRIPT: 'Bachelors Transcript',
		DIPLOMA_TRANSCRIPT: 'Diploma Transcript',
		G10_CERTIFICATE: 'Grade 10 Certificate',
		G11_CERTIFICATE: 'Grade 11 Certificate',
		HIGH_SCHOOL_GRADUATION_CERTIFICATE: 'High School Graduation Certificate',
		BACHELORS_GRADUATION_CERTIFICATE: 'Bachelors Graduation Certificate',
		IELTS: 'IELTS English Proficiency Test',
		TOEFL: 'TOEFL English Proficiency Test',
		DUOLINGO: 'Duolingo English Proficiency Test',
		PTE: 'PTE English Proficiency Test',
		CAMBRIDGE: 'Cambridge English Proficiency Test',
		OET: 'OET English Proficiency Test',
		FINANCIAL_DOCUMENTS: 'Financial Documents',
		PROOF_OF_NAME_CHANGE: 'Proof of Name Change',
		ENGLISH_PROFICIENCY_TEST_EXEMPTION: 'English Proficiency Test Exemption',
		RESUME: 'Resume',
	};

export type ApplicationStatusDocumentTypes = 'REJECTION_LETTER' | 'WAITLIST';

export const APPLICATION_STATUS_DOCUMENT_TYPES: {
	[key in ApplicationStatusDocumentTypes]: string;
} = {
	REJECTION_LETTER: 'Rejection Letter',
	WAITLIST: 'Waitlist',
};

export type RequiredApplicationDocumentTypes =
	| 'PROOF_OF_VACCINE'
	| 'STATEMENT_OF_PURPOSE'
	| 'STUDY_GAP_EXPLANATION'
	| 'LETTER_OF_REFERENCE'
	| 'AUTHORIZATION_FORM'
	| 'DECLARATION_FORM'
	| 'PROGRAM_ESSAY'
	| 'COMPLETE_APPLICATION_PACKAGE'
	| 'LETTER_OF_ACCEPTANCE';

export const REQUIRED_APPLICATION_DOCUMENT_TYPES: {
	[type in RequiredApplicationDocumentTypes]: string;
} = {
	PROOF_OF_VACCINE: 'Proof of Vaccine',
	STATEMENT_OF_PURPOSE: 'Statement of Purpose',
	STUDY_GAP_EXPLANATION: 'Study Gap Explanation',
	LETTER_OF_REFERENCE: 'Letter of Reference',
	AUTHORIZATION_FORM: 'Authorization Form',
	DECLARATION_FORM: 'Declaration Form',
	PROGRAM_ESSAY: 'Program Essay',
	COMPLETE_APPLICATION_PACKAGE: 'Complete Application Package',
	LETTER_OF_ACCEPTANCE: 'Letter of Acceptance',
};

export type ApplicationDocumentTypes =
	| RequiredApplicationDocumentTypes
	| ApplicationStatusDocumentTypes
	| 'OTHER';

export const APPLICATION_DOCUMENT_TYPES: {
	[type in ApplicationDocumentTypes]: string;
} = {
	...REQUIRED_APPLICATION_DOCUMENT_TYPES,
	...APPLICATION_STATUS_DOCUMENT_TYPES,
	OTHER: 'Other',
};

export type OnshoreRequiredDocumentTypes = 'STUDY_PERMIT_LETTER';

export const ONSHORE_REQUIRED_DOCUMENT_TYPES: {
	[type in OnshoreRequiredDocumentTypes]: string;
} = {
	STUDY_PERMIT_LETTER: 'Study Permit Letter',
};

export type OnshoreDocumentTypes =
	| OnshoreRequiredDocumentTypes
	| 'LETTER_OF_ACCEPTANCE'
	| 'LETTER_OF_ENROLLMENT'
	| 'STUDY_VISA';

export const ONSHORE_DOCUMENT_TYPES: {[type in OnshoreDocumentTypes]: string} =
	{
		...ONSHORE_REQUIRED_DOCUMENT_TYPES,
		STUDY_VISA: 'Study Visa',
		LETTER_OF_ACCEPTANCE: 'Letter of Acceptance',
		LETTER_OF_ENROLLMENT: 'Letter of Enrollment',
	};

export type DocumentTypes =
	| GeneralDocumentTypes
	| ApplicationDocumentTypes
	| OnshoreDocumentTypes;

export const DOCUMENT_TYPES: {[type in DocumentTypes]: string} = {
	...GENERAL_DOCUMENT_TYPES,
	...APPLICATION_DOCUMENT_TYPES,
	...ONSHORE_DOCUMENT_TYPES,
};

export type RequiredDocumentTypes =
	| GeneralDocumentTypes
	| RequiredApplicationDocumentTypes
	| OnshoreRequiredDocumentTypes;

export const REQUIRED_DOCUMENT_TYPES: {
	[type in RequiredDocumentTypes]: string;
} = {
	...GENERAL_DOCUMENT_TYPES,
	...REQUIRED_APPLICATION_DOCUMENT_TYPES,
	...ONSHORE_REQUIRED_DOCUMENT_TYPES,
};

export const STUDENT_DOCUMENT_COMPLETE_TYPES: {
	[type in StudentDocumentTypes]: string;
} = {
	...GENERAL_DOCUMENT_TYPES,
	...APPLICATION_DOCUMENT_TYPES,
	...ONSHORE_DOCUMENT_TYPES,
};

export const STUDENT_DOCUMENT_TYPES = [
	...Object.keys(GENERAL_DOCUMENT_TYPES),
	...Object.keys(APPLICATION_DOCUMENT_TYPES),
	...Object.keys(ONSHORE_DOCUMENT_TYPES),
] as const;

export type OnshoreStudentDocumentTypes =
	| GeneralDocumentTypes
	| OnshoreDocumentTypes;

export const ONSHORE_STUDENT_DOCUMENT_TYPES: {
	[type in OnshoreStudentDocumentTypes]: string;
} = {
	...GENERAL_DOCUMENT_TYPES,
	...ONSHORE_DOCUMENT_TYPES,
};

// Document Statuses
export const STUDENT_DOCUMENT_STATUS_NAMES: {[key: string]: string} = {
	PENDING: 'students.documents.status.pending',
	VERIFIED: 'students.documents.status.verified',
};

export const STUDENT_DOCUMENT_STATUSES = [
	...Object.keys(STUDENT_DOCUMENT_STATUS_NAMES),
];
export type StudentDocumentStatusType =
	(typeof STUDENT_DOCUMENT_STATUSES)[number];
