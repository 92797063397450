import {
	GraphQLClientError as ClientError,
	ApiClientProvider,
	SocketProvider,
} from '@halp/api';
import {ToastProvider} from '@halp/ui';
import {ToastContext} from '@halp/ui/src/Toast/ToastProvider';
import {UserContext, UserProvider} from '../../Users';
import {NotificationProvider} from '../../Notifications/NotificationProvider';
import type {SessionUser} from '../../Users';
import type {ReactNode} from 'react';

interface Props {
	children: ReactNode;
	logoutMessage: string;
	dehydratedState: unknown;
	onUserLoaded?: (user: SessionUser) => void;
}

export function AppProvider({children, dehydratedState, ...props}: Props) {
	return (
		<ToastProvider>
			<ToastContext.Consumer>
				{(addToast) => (
					<ApiClientProvider
						onError={(error) => {
							if (
								error instanceof ClientError &&
								error.response.statuscode === 504
							) {
								addToast({message: error.message, type: 'error'});
							}
						}}
						dehydratedState={dehydratedState}
					>
						<UserProvider {...props}>
							<UserContext.Consumer>
								{(value) => (
									<SocketProvider
										url="/user-socket"
										options={{params: {token: value?.user?.token || null}}}
									>
										<NotificationProvider>{children}</NotificationProvider>
									</SocketProvider>
								)}
							</UserContext.Consumer>
						</UserProvider>
					</ApiClientProvider>
				)}
			</ToastContext.Consumer>
		</ToastProvider>
	);
}
