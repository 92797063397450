import {type ReactNode, useMemo, createContext} from 'react';

export const FeatureFlagContext = createContext({
	enabledFeatures: [] as readonly string[],
});

interface Props {
	children: ReactNode;
	enabledFeatures?: readonly string[];
}

export function FeatureFlagProvider({children, enabledFeatures = []}: Props) {
	const memoizedEnabledFeatures = useMemo(
		() => ({enabledFeatures}),
		[enabledFeatures],
	);

	return (
		<FeatureFlagContext.Provider value={memoizedEnabledFeatures}>
			{children}
		</FeatureFlagContext.Provider>
	);
}
