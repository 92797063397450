import {type ChangeEvent, useEffect, useState} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import classNames from 'classnames';
import {Stack} from '@halp/ui';
import type {SelectOptions} from '@halp/ui/Select';
import {Select} from '@halp/ui/Select';
import {FormField} from './FormField';
import style from './FormField.module.css';
import type {BaseInputProps} from './FormField';

interface CurrencyProps extends BaseInputProps {
	disabled?: boolean;
	value?: string;
	dropdownOptions?: SelectOptions<Option>;
	children?: null;
	amountClassName?: string;
	currencyClassName?: string;
	formFieldClassName?: string;
	labelClassName?: string;
}

interface Option {
	label: string;
	value: string | number;
}

const DEFAULT_CURRENCY_CODES = [
	{label: 'CAD', value: 'CAD'},
	{label: 'GBP', value: 'GBP'},
	{label: 'USD', value: 'USD'},
	{label: 'AUD', value: 'AUD'},
];

export function FormCurrencyField({
	disabled = false,
	label,
	placeholder,
	optional,
	name,
	rules,
	dropdownOptions = DEFAULT_CURRENCY_CODES,
	amountClassName,
	currencyClassName,
	formFieldClassName,
	labelClassName,
}: CurrencyProps) {
	const {register, setValue} = useFormContext();

	const currencyValue = useWatch({name});
	const [currency, setCurrency] = useState(currencyValue);

	useEffect(() => {
		if (currency) {
			setValue(name, `${currency.amount} ${currency.currency}`);
		}
	}, [currencyValue, setValue, currency, name]);

	useEffect(() => {
		register(name, rules);
	}, [register, name, rules]);

	const onCurrencyCodeChange = (option: Option) => {
		setCurrency({amount: currency?.amount || '0.00', currency: option?.value});
		setValue(
			name,
			`${currency?.amount ? currency.amount : 0.0} ${option?.value}`,
			{
				shouldDirty: true,
			},
		);
	};

	const onCurrencyValueChange = (value: string) => {
		setCurrency({
			amount: value,
			currency: currency?.currency || DEFAULT_CURRENCY_CODES[0].value,
		});
		setValue(
			name,
			`${value} ${
				currency?.currency ? currency.currency : DEFAULT_CURRENCY_CODES[0].value
			}`,
			{
				shouldDirty: true,
			},
		);
	};

	return (
		<FormField
			name={name}
			label={label}
			optional={optional}
			className={formFieldClassName}
			labelClassName={labelClassName}
		>
			<Stack>
				<input
					value={currency ? currency.amount : null}
					type="number"
					step="0.01"
					min="0.00"
					disabled={disabled}
					placeholder={placeholder}
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						onCurrencyValueChange(e.target.value);
					}}
					className={amountClassName}
				/>
				<Select
					className={classNames(style.CurrencyInput, currencyClassName)}
					defaultValue={
						currency
							? {label: currency.currency, value: currency.currency}
							: null
					}
					isDisabled={disabled}
					onChange={(option) => onCurrencyCodeChange(option as Option)}
					options={dropdownOptions}
					value={dropdownOptions.filter(
						(option) => currency?.currency === option.value,
					)}
					styles={{
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						control: (provided: any) => ({
							...provided,
							border: 'solid 1px var(--base-color-lightest)',
							borderRadius: 'var(--form-field-border-radius)',
							color: 'var(--base-color-dark)',
							fontSize: '1rem',
							padding: '0.3rem',
							marginLeft: '0.4rem',
						}),
					}}
				/>
			</Stack>
		</FormField>
	);
}
