export type ScreenSize = 'empty' | 'sm' | 'md' | 'lg' | 'xl';

export const SIZE_ORDER: {order: number; size: ScreenSize}[] = [
	{order: 0, size: 'empty'},
	{order: 1, size: 'sm'},
	{order: 2, size: 'md'},
	{order: 3, size: 'lg'},
	{order: 4, size: 'xl'},
];

export const SCREEN_SIZE_MAX_WIDTHS = {
	empty: 0,
	sm: 414,
	md: 768,
	lg: 1440,
	xl: 1920,
};

function constructMediaQuery(
	first?: ScreenSize | number,
	second?: ScreenSize | number,
) {
	if (typeof window === 'undefined') {
		return null;
	}

	if (first && second === undefined) {
		const minWidth =
			typeof first === 'number'
				? `${first + 1}`
				: `${SCREEN_SIZE_MAX_WIDTHS[first] + 1}`;

		return window.matchMedia(`(min-width: ${minWidth}px)`);
	} else if (second && first === undefined) {
		const maxWidth =
			typeof second === 'number'
				? `${second}`
				: `${SCREEN_SIZE_MAX_WIDTHS[second]}`;
		return window.matchMedia(`(max-width: ${maxWidth}px)`);
	} else if (first && second) {
		const minWidth =
			typeof first === 'number'
				? `${first + 1}`
				: `${SCREEN_SIZE_MAX_WIDTHS[first] + 1}`;
		const maxWidth =
			typeof second === 'number'
				? `${second}`
				: `${SCREEN_SIZE_MAX_WIDTHS[second]}`;
		return window.matchMedia(
			`(min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`,
		);
	}

	return null;
}

export function getMediaQuery(
	min?: ScreenSize | number,
	max?: ScreenSize | number,
	size?: ScreenSize,
) {
	if (size) {
		const currentSize = SIZE_ORDER.find((e) => e.size === size);
		const previousSize = SIZE_ORDER.find((e) => {
			if (currentSize) {
				return currentSize.order - 1 === e.order;
			}
			return undefined;
		});

		return constructMediaQuery(previousSize?.size, size);
	}
	return constructMediaQuery(min, max);
}
