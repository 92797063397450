import type {SelectOptions} from '@halp/ui';
import {Stack, Select} from '@halp/ui';
import {useCountryNames} from '@halp/util';
import {usePushQueryParams} from '../../../PushWithQueryParams';
import {useI18n} from '../../../i18n';
import style from './CountryProvinceSelector.module.css';

interface Option {
	label: string;
	value: string;
}

interface Props {
	country?: string;
	setCountry: (country?: string) => void;
	countryOptions: SelectOptions<Option>;
	province?: string | null;
	setProvince: (province?: string) => void;
	provinceOptions: SelectOptions<Option>;
	shouldPushQueryParams?: boolean;
	className?: string;
}

export function CountryProvinceSelector({
	country,
	setCountry,
	countryOptions,
	province,
	setProvince,
	provinceOptions,
	shouldPushQueryParams = true,
	className,
}: Props) {
	const {t} = useI18n();
	const pushQueryParams = usePushQueryParams();
	const countryNames = useCountryNames();

	return (
		<Stack spacing="xs" fullWidth className={className}>
			<Select
				className={style.Select}
				isClearable
				placeholder={t('filters.country')}
				value={country ? {label: country, value: country} : null}
				onChange={(option) => {
					setCountry(option?.value as string);
					if (shouldPushQueryParams) {
						pushQueryParams({country: option?.value as string});
					}
					if (!option) {
						setProvince(undefined);
						pushQueryParams({province: undefined});
					}
				}}
				options={
					countryOptions ??
					countryNames.map((country) => {
						return {label: country, value: country};
					})
				}
			/>
			<Select
				className={style.Select}
				isClearable
				placeholder={t('filters.province')}
				isDisabled={!country}
				value={province ? {label: province, value: province} : null}
				onChange={(option) => {
					setProvince(option?.value as string);
					if (shouldPushQueryParams) {
						pushQueryParams({province: option?.value as string});
					}
				}}
				options={provinceOptions}
			/>
		</Stack>
	);
}
