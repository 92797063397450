import {Select} from '@halp/ui';
import type {AcceptedEnglishProficiencyTests, LevelOfStudy} from '@halp/util';
import {
	STUDY_LEVELS,
	formatIntakePeriod,
	ACCEPTED_EP_TESTS,
	BOOLEAN_STRING,
} from '@halp/util';
import type {Options} from '@halp/ui/src/Select/Select';
import {useI18n} from '../../i18n';
import {usePushQueryParams} from '../../PushWithQueryParams';
import {
	CountryProvinceSelector,
	TuitionRangeSelector,
} from '../ProgramSelectors';
import {GradeRangeSelector} from '../ProgramSelectors/GradeRangeSelector/GradeRangeSelector';
import {ProgramLengthRangeSelector} from '../ProgramSelectors/ProgramLengthSelector';
import style from './ProgramFilters.module.css';
import type {Props as ProgramFilterProps} from './ProgramFilters';

interface Option {
	label: string;
	value: string;
}

interface Props extends Omit<ProgramFilterProps, 'filters'> {
	studyLevelOptions: Options<{label: string; value: LevelOfStudy}>;
	selectedSchool?: {
		id: string;
		name: string;
		province: string;
	} | null;
	schoolOptions: Options<Option>;
	selectedSubject: {
		id: string;
		name: string;
	};
	subjectOptions: Options<Option>;
	selectedMajor: {
		id: string;
		name: string;
	};
	majorOptions: Options<Option>;
	countryOptions: Options<Option>;
	provinceOptions: Options<Option>;
	intakePeriodOptions: Options<Option>;
	selectedIntakePeriod: {
		startDate: string;
		intakePeriod: string;
	} | null;
	acceptedEpTestOptions: Options<Option>;
	studentGrade?: number | null;
	submissionDirectOptions: Options<{label: string; value: boolean}>;
}

export function useProgramFilters({
	studyLevelOptions,
	levelOfStudy,
	setLevelOfStudy,
	selectedSchool,
	schoolOptions,
	schoolId,
	setSchool,
	selectedSubject,
	subjectOptions,
	setSubject,
	selectedMajor,
	majorOptions,
	setMajor,
	countryOptions,
	country,
	setCountry,
	provinceOptions,
	province,
	setProvince,
	intakePeriodOptions,
	selectedIntakePeriod,
	setIntakePeriod,
	tuitionRange,
	setTuitionRange,
	programLengthRange,
	setProgramLengthRange,
	acceptedEpTestOptions,
	acceptedEpTest,
	setAcceptedEpTest,
	studentGrade,
	requiredGradeRange,
	setRequiredGradeRange,
	submissionDirectOptions,
	submissionDirect,
	shouldPushQueryParams = true,
}: Props) {
	const {t} = useI18n();

	const pushQueryParams = usePushQueryParams();

	return {
		levelOfStudy: (
			<Select
				className={style.Select}
				isClearable
				placeholder={t('filters.level_of_study.title')}
				value={
					levelOfStudy
						? {label: t(STUDY_LEVELS[levelOfStudy]), value: levelOfStudy}
						: null
				}
				onChange={(option) => {
					setLevelOfStudy?.(option?.value);
					if (shouldPushQueryParams) {
						pushQueryParams({level: option?.value});
					}
				}}
				options={studyLevelOptions}
			/>
		),
		school: (
			<Select
				className={style.Select}
				isClearable
				placeholder={t('filters.school')}
				onChange={(option) => {
					setSchool?.(option?.value);
					if (shouldPushQueryParams) {
						pushQueryParams({school: option?.value});
					}
				}}
				value={
					selectedSchool ? {label: selectedSchool.name, value: schoolId} : null
				}
				options={schoolOptions}
			/>
		),
		subject: (
			<Select
				className={style.Select}
				isClearable
				placeholder={t('filters.subject')}
				onChange={(option) => {
					setSubject?.(option?.value);
					if (shouldPushQueryParams) {
						pushQueryParams({
							// eslint-disable-next-line @typescript-eslint/naming-convention
							'subject-id': option?.value,
							// eslint-disable-next-line @typescript-eslint/naming-convention
							'subject-name': option?.label,
						});
					}
				}}
				value={
					selectedSubject.name
						? {label: selectedSubject.name, value: selectedSubject.id}
						: null
				}
				options={subjectOptions}
			/>
		),
		major: (
			<Select
				className={style.Select}
				isClearable
				placeholder={t('filters.major')}
				onChange={(option) => {
					setMajor?.(option?.value);
					if (shouldPushQueryParams) {
						pushQueryParams({
							// eslint-disable-next-line @typescript-eslint/naming-convention
							'major-id': option?.value,
							// eslint-disable-next-line @typescript-eslint/naming-convention
							'major-name': option?.label,
						});
					}
				}}
				value={
					selectedMajor.name
						? {label: selectedMajor.name, value: selectedMajor.id}
						: null
				}
				options={majorOptions}
			/>
		),
		country: (
			<CountryProvinceSelector
				country={country}
				setCountry={(country) => {
					setCountry?.(country);
					if (shouldPushQueryParams) {
						pushQueryParams({country: country});
					}
				}}
				countryOptions={countryOptions}
				provinceOptions={provinceOptions}
				province={province}
				setProvince={(province) => {
					setProvince?.(province);
					if (shouldPushQueryParams) {
						pushQueryParams({province: province});
					}
				}}
				className={style.CountryProvinceGrid}
			/>
		),
		intakePeriod: (
			<Select
				className={style.Select}
				isClearable
				placeholder={t('filters.intake_period')}
				onChange={(option) => {
					setIntakePeriod?.(option?.value);
					if (shouldPushQueryParams) {
						pushQueryParams({
							// eslint-disable-next-line @typescript-eslint/naming-convention
							'intake-period': option?.value,
						});
					}
				}}
				value={
					selectedIntakePeriod
						? {
								label: formatIntakePeriod(selectedIntakePeriod.intakePeriod),
								value: selectedIntakePeriod.startDate,
							}
						: null
				}
				options={intakePeriodOptions}
			/>
		),
		tuition: (
			<TuitionRangeSelector
				value={tuitionRange ?? undefined}
				onChange={(arg) => {
					setTuitionRange?.(arg);
					if (shouldPushQueryParams) {
						pushQueryParams({
							// eslint-disable-next-line @typescript-eslint/naming-convention
							'tuition-min':
								arg.min !== null && !isNaN(arg.min) ? `${arg.min}` : undefined,
							// eslint-disable-next-line @typescript-eslint/naming-convention
							'tuition-max':
								arg.max !== null && !isNaN(arg.max) ? `${arg.max}` : undefined,
						});
					}
				}}
			/>
		),
		programLength: (
			<ProgramLengthRangeSelector
				minLength={programLengthRange?.minLength}
				maxLength={programLengthRange?.maxLength}
				placeholder={t('program_length_range_selector.placeholder')}
				onChange={(lowerBound, upperBound) => {
					setProgramLengthRange?.({
						minLength: lowerBound,
						maxLength: upperBound,
					});
					if (shouldPushQueryParams) {
						pushQueryParams({
							// eslint-disable-next-line @typescript-eslint/naming-convention
							'program-length-min': `${lowerBound.year}-${lowerBound.month}-${lowerBound.week}`,
							// eslint-disable-next-line @typescript-eslint/naming-convention
							'program-length-max': `${upperBound.year}-${upperBound.month}-${upperBound.week}`,
						});
					}
				}}
			/>
		),
		grade: studentGrade ? (
			<GradeRangeSelector
				minGrade={requiredGradeRange?.min}
				maxGrade={requiredGradeRange?.max}
				studentGrade={studentGrade}
				onChange={(lowerBound, upperBound) => {
					setRequiredGradeRange?.({
						min: lowerBound,
						max: upperBound,
					});
					if (shouldPushQueryParams) {
						pushQueryParams({
							// eslint-disable-next-line @typescript-eslint/naming-convention
							'required-grade-min': `${lowerBound}`,
							// eslint-disable-next-line @typescript-eslint/naming-convention
							'required-grade-max': `${upperBound}`,
						});
					}
				}}
			/>
		) : (
			<Select isDisabled placeholder={t('grade_range_selector.title')} />
		),
		acceptedEpTest: (
			<Select
				className={style.Select}
				isClearable
				placeholder={t('filters.accepted_ep_test.title')}
				value={
					acceptedEpTest
						? {
								label: t(
									ACCEPTED_EP_TESTS[
										acceptedEpTest as AcceptedEnglishProficiencyTests
									],
								),
								value: acceptedEpTest,
							}
						: null
				}
				onChange={(option) => {
					setAcceptedEpTest?.(option?.value);
					if (shouldPushQueryParams) {
						// eslint-disable-next-line @typescript-eslint/naming-convention
						pushQueryParams({'accepted-ep-test': option?.value});
					}
				}}
				options={acceptedEpTestOptions}
			/>
		),
		submissionDirect: (
			<Select
				className={style.Select}
				isClearable
				placeholder={t('filters.submission_direct')}
				value={
					submissionDirect != null
						? {
								label: t(BOOLEAN_STRING[`${submissionDirect}`]),
								value: submissionDirect,
							}
						: null
				}
				onChange={(option) => {
					if (shouldPushQueryParams) {
						// eslint-disable-next-line @typescript-eslint/naming-convention
						pushQueryParams({'submission-direct': `${option?.value}`});
					}
				}}
				options={submissionDirectOptions}
			/>
		),
	};
}
