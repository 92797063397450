/* eslint-disable @typescript-eslint/naming-convention */
import {DayPicker} from 'react-day-picker';
import classNames from 'classnames';
import {useAsyncCss} from '../use-async-css';
import style from './Calendar.module.css';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

export function Calendar({
	className,
	classNames: customClassNames,
	showOutsideDays = true,
	captionLayout = 'dropdown',
	...props
}: CalendarProps) {
	useAsyncCss('/css/daypicker.css');

	let start = new Date();

	if (props.mode === 'single') {
		start = props.selected ?? start;
	} else if (props.mode === 'range') {
		start = props.selected?.from ?? start;
	} else if (props.mode === 'multiple') {
		start = props.selected?.[0] ?? start;
	}

	return (
		<DayPicker
			showOutsideDays={showOutsideDays}
			captionLayout={captionLayout}
			defaultMonth={start}
			className={classNames(style.Calendar, className)}
			classNames={{
				selected: style.DaySelected,
				today: style.DayToday,
				chevron: style.Chevron,
				range_start: style.RangeStart,
				range_end: style.RangeEnd,
				range_middle: style.RangeMiddle,
				...customClassNames,
			}}
			{...props}
		/>
	);
}
