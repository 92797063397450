import NextImage from 'next/image';
import classNames from 'classnames';
import style from './Image.module.css';
import type {ImageProps} from 'next/image';

export interface Props extends Omit<ImageProps, 'width' | 'height'> {
	objectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
}

export function Image({className, ...props}: Props) {
	const classes = classNames(style.Image, className);

	return (
		<div className={classNames(style.ImageContainer, className)}>
			<NextImage fill className={classes} {...props} />
		</div>
	);
}

// export const Image = NextImage;
