import type {InputMaybe} from '@halp/api/types.graphql';
interface Props {
	slug: string;
	utmSource: string;
	utmMedium: string;
	utmCampaign: string;
	utmId: InputMaybe<string> | undefined;
	utmContent: InputMaybe<string> | undefined;
}

export const TRACKING_PATHS = {
	trackingUrl: ({
		slug,
		utmSource,
		utmMedium,
		utmCampaign,
		utmId,
		utmContent,
	}: Props) => {
		const utmIdParam = utmId ? `&utm_id=${utmId}` : '';
		const utmContentParam = utmContent ? `&utm_content=${utmContent}` : '';

		return `https://halp.co/${slug}?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}${utmIdParam}${utmContentParam}`
			.replace(/\s+/g, '-')
			.toLowerCase();
	},
};
