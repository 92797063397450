import {
	getIntakePeriodSeason,
	getIntakePeriodYear,
} from './parse-intake-period';

export function intakePeriodToDate(intakePeriod: string) {
	// January = Month 0
	const monthMappings = {
		WINTER: 0,
		SPRING: 4,
		SUMMER: 6,
		FALL: 8,
	};

	const season = getIntakePeriodSeason(intakePeriod);
	const year = getIntakePeriodYear(intakePeriod);

	const month = monthMappings[season as keyof typeof monthMappings];
	return new Date(year, month, 1);
}
