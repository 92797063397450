import {useEffect, useState} from 'react';
import {isBrowser} from '@halp/util';
import {getMediaQuery} from './BreakpointUtil';
import type {ScreenSize} from './BreakpointUtil';

interface SizeParams {
	size: ScreenSize;
	max?: never;
	min?: never;
}

interface MinMaxParams {
	min?: ScreenSize | number;
	max?: ScreenSize | number;
	size?: never;
}

type Params = SizeParams | MinMaxParams;

export function useBreakpoint(params: Params) {
	const {min, max} = params;
	const {size} = params;
	const isServer = !isBrowser();

	const mediaQuery = isServer ? null : getMediaQuery(min, max, size);

	const [match, setMatch] = useState(mediaQuery?.matches ?? false);

	useEffect(() => {
		if (mediaQuery) {
			mediaQuery.addEventListener('change', () => setMatch(mediaQuery.matches));
			return () =>
				mediaQuery.addEventListener('change', () =>
					setMatch(mediaQuery.matches),
				);
		}
		return () => null;
	}, [mediaQuery, setMatch, min, max, size]);

	return match;
}
