import {useFormContext, useWatch} from 'react-hook-form';
import {Stack, Heading} from '@halp/ui';
import type {RequiredDocumentTypes} from '@halp/util/constants';
import {REQUIRED_DOCUMENT_TYPES} from '@halp/util/constants';
import {useI18n} from '../i18n';
import {FormSelect} from '../Form';
import {SelectedDocumentType} from './SelectedDocumentType';
import type {SelectOption} from '../Form';

interface Props {
	name: string;
}

export function DocumentTypeSelector({name}: Props) {
	const {t} = useI18n();
	const {setValue} = useFormContext();
	const currentDocuments = useWatch({
		name: `${name}`,
	}) as RequiredDocumentTypes[];

	function handleRequiredDoc(option: SelectOption) {
		setValue(name, [...currentDocuments, option.value]);
	}

	function removeRequiredDoc(documentType: string) {
		const newDocuments = currentDocuments.filter((doc) => doc !== documentType);
		setValue(name, newDocuments);
	}

	const requiredDocOptions = Object.entries(REQUIRED_DOCUMENT_TYPES)
		.filter(([key, _value]) => {
			const inCurrentEntries = !!currentDocuments.includes(
				key as RequiredDocumentTypes,
			);
			return !inCurrentEntries;
		})
		.map(([key, value]) => ({
			label: value,
			value: key,
		}));

	return (
		<Stack direction="column" alignItems="stretch">
			<Heading type="h5" spacing="md">
				{t('program_recommendation.application_requirements.title')}
			</Heading>
			<FormSelect
				name="docs"
				options={requiredDocOptions}
				onChange={(option) => handleRequiredDoc(option as SelectOption)}
			/>
			<SelectedDocumentType
				documents={currentDocuments}
				onDelete={removeRequiredDoc}
			/>
		</Stack>
	);
}
