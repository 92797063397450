import {Controller, useFormContext} from 'react-hook-form';
import type {SelectOptions} from '@halp/ui/Select';
import {Select} from '@halp/ui/Select';
import {FormField} from './FormField';
import type {BaseInputProps} from './FormField';

export interface Option {
	label: string;
	value: string | number | boolean;
}

export interface Props extends Omit<BaseInputProps, 'onChange'> {
	options: SelectOptions<Option>;
	isMulti?: boolean;
	maxSelections?: number;
	onChange?: (option: Option | readonly Option[] | null) => void;
	isClearable?: boolean;
	inputClassName?: string;
	formFieldClassName?: string;
}

export function FormSelect({
	name,
	label,
	rules,
	placeholder,
	optional,
	options,
	isMulti,
	maxSelections,
	onChange,
	isClearable = false,
	inputClassName: className,
	formFieldClassName,
	disabled = false,
	value: initialValue,
}: Props) {
	const {watch} = useFormContext();

	return (
		<FormField
			name={name}
			label={label}
			optional={optional}
			className={formFieldClassName}
		>
			<Controller
				name={name}
				rules={rules}
				defaultValue={initialValue}
				render={({field: {onChange: onControllerChange, onBlur}}) => {
					const fieldValue = watch(name);
					const handleChange = (option: Option | readonly Option[] | null) => {
						onChange?.(option);
						if (isMulti && Array.isArray(option)) {
							const updateValue = (option as Option[]).map(
								(item) => item.value,
							);
							return onControllerChange(updateValue);
						} else {
							return onControllerChange((option as Option | null)?.value);
						}
					};

					const opts =
						isMulti && fieldValue && maxSelections === fieldValue.length
							? fieldValue
							: options;

					const value = isMulti
						? options.filter(
								(option: Option) =>
									fieldValue && fieldValue.indexOf(option.value) >= 0,
							)
						: options.find((option: Option) => option.value === fieldValue);

					return (
						<Select
							name={name}
							options={opts}
							onChange={handleChange}
							isDisabled={disabled}
							placeholder={placeholder}
							onBlur={onBlur}
							value={value}
							isMulti={isMulti}
							isClearable={isClearable}
							backspaceRemovesValue={true}
							maxMenuHeight={200}
							className={className}
							captureMenuScroll
							classNamePrefix="form-select"
							styles={{
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								control: (provided: any) => ({
									...provided,
									border: 'solid 1px var(--base-color-lightest)',
									borderRadius: 'var(--form-field-border-radius)',
									color: 'var(--base-color-dark)',
									fontSize: '1rem',
									lineHeight: 1.38,
									padding: '0.4rem',
								}),
								option: (styles, {isFocused, isSelected}) => {
									return {
										// eslint-disable-next-line @typescript-eslint/no-explicit-any
										...(styles as any),
										backgroundColor:
											isFocused || isSelected
												? 'var(--formselect-focus-background-color)'
												: 'none',
										color: 'var(--formselect-color)',
									};
								},
							}}
						/>
					);
				}}
			/>
		</FormField>
	);
}
