/* eslint-disable @typescript-eslint/naming-convention */
import {Path} from './path';
import {ADMIN_PATHS} from './admin';
import {COACH_PATHS} from './coach';
import {LEARNING_PATHS} from './learning';
import {MARKETING_PATHS} from './marketing';
import {STUDENT_PATHS} from './student';
import {PARTNER_PATHS} from './partner';
import {TRACKING_PATHS} from './tracking';
import {USER_PATHS} from './user';
import {Domains} from './domains';
import {PROGRAM_PATHS} from './program';

export const Paths = {
	marketing: MARKETING_PATHS,
	learning_hub: LEARNING_PATHS,
	admin: ADMIN_PATHS,
	coach: COACH_PATHS,
	student: STUDENT_PATHS,
	partner: PARTNER_PATHS,
	tracking: TRACKING_PATHS,
	users: USER_PATHS,
	programs: PROGRAM_PATHS,
	dashboard: new Path('/', Domains.go),
	signup: new Path('/signup', Domains.go),
	qualification: new Path('/qualification', Domains.go),
	onboarding: new Path('/onboarding', Domains.go),
	login: {
		index: new Path('/login', Domains.go),
		reset: new Path('/login/reset', Domains.go),
	},
	account: {
		index: new Path('/account'),
		profile: new Path('/account/profile'),
		application: new Path('/account/application'),
		offers: new Path('/account/offers'),
		payment: new Path('/account/payment'),
		settings: new Path('/account/settings'),
	},
	blog: new Path('https://learning.halp.co/blog'),
	lost: new Path((type?: 'coach' | 'admin' | 'partner' | 'student') => {
		if (type === 'coach') {
			return new Path('/404', Domains.coach).url();
		} else if (type === 'admin') {
			return new Path('/404', Domains.admin).url();
		} else if (type === 'partner') {
			return new Path('/404', Domains.partner).url();
		}
		return new Path('/404', Domains.go).url();
	}),
};
