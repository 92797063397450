import {
	type ReactNode,
	type CSSProperties,
	type ForwardedRef,
	forwardRef,
} from 'react';
import classNames from 'classnames';
import {CSSVariants} from '@halp/util';
import style from './Surface.module.css';
import type {SurfaceColor} from './type';

export interface Props {
	children: ReactNode;
	padding?: 'omit' | 'none' | 'xs' | 'sm' | 'md' | 'lg';
	border?: 'subdued' | 'dashed';
	borderRadius?: 'none';
	shadow?: 'none';
	hoverShadow?: boolean;
	hoverColor?: SurfaceColor;
	color?: SurfaceColor;
	className?: string;
	style?: CSSProperties;
	id?: string;
}

export const Surface = forwardRef(
	(
		{
			padding = 'sm',
			borderRadius,
			border,
			shadow,
			hoverShadow,
			color,
			hoverColor,
			className,
			...props
		}: Props,
		ref: ForwardedRef<HTMLDivElement>,
	) => {
		Surface.displayName = 'Surface';
		const classes = classNames(
			style.Surface,
			padding !== 'omit' && CSSVariants(style, 'SurfacePadding', padding),
			CSSVariants(style, 'SurfaceBorderRadius', borderRadius),
			CSSVariants(style, 'SurfaceBorder', border),
			CSSVariants(style, 'SurfaceShadow', shadow),
			CSSVariants(style, 'SurfaceHoverShadow', hoverShadow),
			CSSVariants(style, 'SurfaceHoverColor', hoverColor),
			CSSVariants(style, 'SurfaceColor', color),
			className,
		);

		return <div className={classes} ref={ref} {...props} />;
	},
);
