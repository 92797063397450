import {faXmark} from '@fortawesome/free-solid-svg-icons';
import {Stack, Link, SkeletonText, DatePicker, Icon} from '@halp/ui';
import {Select} from '@halp/ui/Select';
import {capitalize, enumTypeToCamel} from '@halp/util/string';
import {
	COACHING_FUNNEL_STAGES,
	COACHING_FUNNEL_STAGE_NAMES,
	QUALIFICATION_STATUSES,
	QUALIFICATION_STATUSES_NAMES,
} from '@halp/util/constants';
import type {
	CoachingFunnelStage,
	QualificationStatus,
} from '@halp/util/constants';
import {dayjs, formatDateTime} from '@halp/util/datetime';
import {formatIntakePeriod, useCountryNames} from '@halp/util';
import {useQueryTable} from '../../DataTable/QueryTableProvider';
import {usePushQueryParams} from '../../PushWithQueryParams';
import {useI18n} from '../../i18n';
import {MemberStatuses} from '../StudentsTable/types';
import style from './StudentFilters.module.css';
import type {StudentFiltersType} from './types';
import type {MemberStatus} from '../StudentsTable/types';

export interface DateRange {
	from: Date;
	to?: Date;
}

interface Props {
	shouldPushQueryParams?: boolean;
	coaches?: readonly {id: string; user: {name: string | null}}[];
	filters?: StudentFiltersType[];
	funnelStage?: CoachingFunnelStage;
	setFunnelStage?: (stage?: CoachingFunnelStage) => void;
	qualificationStatus?: QualificationStatus;
	setQualStatus?: (status?: QualificationStatus) => void;
	intakePeriod: string[];
	setIntakePeriod: (intakePeriod: string[]) => void;
	signUpDate?: DateRange;
	setSignUpDate?: (signUpDate: DateRange | undefined) => void;
	coach?: {id: string; name: string};
	setCoach?: (coach?: {id: string; name: string}) => void;
	memberStatus?: MemberStatus;
	setMemberStatus?: (status: MemberStatus) => void;
	country?: string;
	setCountry?: (country: string) => void;
	onshore?: boolean;
	setOnshore?: (onshore?: boolean) => void;
}

interface FiltersProps extends Omit<Props, 'filters' | 'coaches'> {
	coachList?: readonly {id: string; user: {name: string | null}}[];
	intakePeriodOptions?: {label: string; value: string}[];
	countryOptions?: {label: string; value: string}[];
}

function useFilters({
	shouldPushQueryParams = true,
	coachList,
	funnelStage,
	setFunnelStage,
	qualificationStatus,
	setQualStatus,
	intakePeriod,
	setIntakePeriod,
	signUpDate,
	setSignUpDate,
	coach,
	setCoach,
	memberStatus,
	setMemberStatus,
	country,
	setCountry,
	onshore,
	setOnshore,
	intakePeriodOptions,
	countryOptions,
}: FiltersProps) {
	const {t} = useI18n();
	const pushQueryParams = usePushQueryParams();
	const countryNames = useCountryNames();

	return {
		funnelStage: (
			<Select
				key="funnelStage"
				className={style.Select}
				isClearable
				placeholder="Funnel Stage"
				options={COACHING_FUNNEL_STAGES.map((funnelStage) => {
					return {
						value: funnelStage,
						label: t(COACHING_FUNNEL_STAGE_NAMES[funnelStage]),
					};
				})}
				onChange={(option) => {
					setFunnelStage?.(option?.value);
					shouldPushQueryParams &&
						pushQueryParams({funnelStage: option?.value});
				}}
				value={
					funnelStage
						? {
								label: t(COACHING_FUNNEL_STAGE_NAMES[funnelStage]),
								value: funnelStage,
							}
						: null
				}
			/>
		),
		qualStatus: (
			<Select
				key="qualStatus"
				className={style.Select}
				isClearable
				placeholder="Qualification Status"
				options={QUALIFICATION_STATUSES.map((status) => {
					return {
						value: enumTypeToCamel(status) as QualificationStatus,
						label: t(QUALIFICATION_STATUSES_NAMES[status]),
					};
				})}
				onChange={(option) => {
					setQualStatus?.(option?.value);
					shouldPushQueryParams &&
						pushQueryParams({qualificationStatus: option?.value});
				}}
				value={
					qualificationStatus
						? {
								label: capitalize(qualificationStatus),
								value: qualificationStatus,
							}
						: null
				}
			/>
		),
		intakePeriod: (
			<Select
				key="intakePeriods"
				className={style.Select}
				isClearable
				placeholder="Intake Period"
				options={intakePeriodOptions}
				onChange={(option) => {
					setIntakePeriod(option?.value ? [option.value] : []);
					shouldPushQueryParams &&
						pushQueryParams({intakePeriod: option?.value ?? undefined});
				}}
				value={
					intakePeriod.length
						? {
								value: intakePeriod[0],
								label: intakePeriod[0],
							}
						: undefined
				}
			/>
		),
		signUpDate: (
			<Stack
				key="signUpDate"
				justifyContent="space-between"
				alignItems="center"
				spacing="xs"
			>
				<DatePicker
					className={style.DatePickerFilter}
					onSelect={(value) => {
						const from = value?.from ? dayjs.utc(value.from) : undefined;
						const to = value?.to ? dayjs.utc(value.to) : undefined;
						const dateRange = from
							? {from: from.toDate(), to: to?.toDate()}
							: undefined;

						setSignUpDate?.(dateRange);
						shouldPushQueryParams &&
							pushQueryParams({
								signUpFrom: from
									? formatDateTime(from, 'DateCompact')
									: undefined,
								signUpTo:
									from && to ? formatDateTime(to, 'DateCompact') : undefined,
							});
					}}
					selected={signUpDate}
					mode="range"
					placeholder="Sign Up Date"
				/>
				<Link
					onClick={() => {
						setSignUpDate?.(undefined);
						shouldPushQueryParams &&
							pushQueryParams({signUpFrom: undefined, signUpTo: undefined});
					}}
				>
					<Icon icon={faXmark} />
				</Link>
			</Stack>
		),
		coach: (
			<Select
				key="coach"
				className={style.Select}
				isClearable
				placeholder="Coaches"
				options={coachList?.map((coach) => {
					return {label: coach.user.name ?? '', value: coach.id};
				})}
				onChange={(option) => {
					if (option == null) {
						setCoach?.(undefined);
						shouldPushQueryParams && pushQueryParams({coach: undefined});
					} else {
						setCoach?.({id: option.value, name: option.label});
						shouldPushQueryParams && pushQueryParams({coach: option.value});
					}
				}}
				value={coach ? {label: coach.name, value: coach.id} : null}
			/>
		),
		memberStatus: (
			<Select
				key="memberStatus"
				className={style.Select}
				isClearable
				placeholder="Member Status"
				options={MemberStatuses.map((status) => {
					return {value: status, label: capitalize(status)};
				})}
				onChange={(option) => {
					setMemberStatus?.(option?.value as MemberStatus);
					shouldPushQueryParams &&
						pushQueryParams({memberStatus: option?.value});
				}}
				value={
					memberStatus
						? {label: capitalize(memberStatus), value: memberStatus}
						: null
				}
			/>
		),
		country: (
			<Select
				key="country"
				className={style.Select}
				isClearable
				placeholder="Country"
				onChange={(option) => {
					setCountry?.(option?.value as string);
					shouldPushQueryParams && pushQueryParams({country: option?.value});
				}}
				options={
					countryOptions ??
					countryNames.map((country) => {
						return {label: country, value: country};
					})
				}
				value={country ? {label: country, value: country} : undefined}
			/>
		),
		onshore: (
			<Select
				key="onshore"
				className={style.Select}
				isClearable
				placeholder="On/Offshore"
				onChange={(option) => {
					setOnshore?.(option?.value);
					if (option) {
						shouldPushQueryParams &&
							pushQueryParams({onshore: `${option?.value}`});
					} else {
						shouldPushQueryParams && pushQueryParams({onshore: undefined});
					}
				}}
				value={
					typeof onshore === 'boolean'
						? {label: onshore ? 'Onshore' : 'Offshore', value: onshore}
						: undefined
				}
				options={[
					{label: 'Onshore', value: true},
					{label: 'Offshore', value: false},
				]}
			/>
		),
	};
}

export function StudentFilters({filters, coaches, ...props}: Props) {
	const {data, isLoading} = useQueryTable();

	const intakePeriodOptions = data?.[0].students?.intakePeriods.map(
		(intakePeriod: string) => ({
			label: formatIntakePeriod(intakePeriod),
			value: intakePeriod,
		}),
	);
	const countryOptions = data?.[0].students?.countries?.map(
		(country: string) => ({
			label: country,
			value: country,
		}),
	);

	const filterMap = useFilters({
		coachList: coaches,
		intakePeriodOptions: intakePeriodOptions,
		countryOptions: countryOptions,
		...props,
	});

	if (isLoading) {
		return <SkeletonText count={1} />;
	}

	if (!filters) {
		return null;
	}

	return (
		<div className={style.Filters}>
			<Stack spacing="xs" justifyContent="flex-start">
				{filters.map((filter) => filterMap[filter])}
			</Stack>
		</div>
	);
}
