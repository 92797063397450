import type * as Types from '@halp/api/types.graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CoachStudentsByFunnelStageQueryVariables = Types.Exact<{
  coachId: Types.Scalars['ID']['input'];
}>;


export type CoachStudentsByFunnelStageQuery = { readonly __typename?: 'RootQueryType', readonly coach: { readonly __typename?: 'Coach', readonly id: string, readonly studentsByFunnelStage: { readonly __typename?: 'StudentsByFunnelStage', readonly signup: number, readonly verification: number, readonly getStarted: number, readonly discover: number, readonly prep: number, readonly submit: number, readonly accepted: number, readonly visa: number, readonly setUp: number, readonly placed: number }, readonly studentsByArchiveState: { readonly __typename?: 'StudentsByArchiveState', readonly archived: number } } | null };


export const CoachStudentsByFunnelStageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CoachStudentsByFunnelStage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"coachId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"coach"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"coachId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"studentsByFunnelStage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"signup"}},{"kind":"Field","name":{"kind":"Name","value":"verification"}},{"kind":"Field","name":{"kind":"Name","value":"getStarted"}},{"kind":"Field","name":{"kind":"Name","value":"discover"}},{"kind":"Field","name":{"kind":"Name","value":"prep"}},{"kind":"Field","name":{"kind":"Name","value":"submit"}},{"kind":"Field","name":{"kind":"Name","value":"accepted"}},{"kind":"Field","name":{"kind":"Name","value":"visa"}},{"kind":"Field","name":{"kind":"Name","value":"setUp"}},{"kind":"Field","name":{"kind":"Name","value":"placed"}}]}},{"kind":"Field","name":{"kind":"Name","value":"studentsByArchiveState"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"archived"}}]}}]}}]}}]} as unknown as DocumentNode<CoachStudentsByFunnelStageQuery, CoachStudentsByFunnelStageQueryVariables>;