import {seoURL} from '@halp/util';
import {Domains} from './domains';
import {Path} from './path';

export const COACH_PATHS = {
	index: new Path('/', Domains.coach),
	handbook:
		'https://www.notion.so/halphq/Handbook-62ec164b01e34152903d7742bd1605e3?pvs=4',
	coaches: {
		index: new Path('/coaches', Domains.coach),
		show: new Path((id: number | string, seo?: string | null) => {
			return `/coaches/show/${id}${seoURL(id, seo)}`;
		}, Domains.coach),
	},
	coaching: {
		show: {
			index: new Path((id: number | string, seo?: string | null) => {
				return `/coaching/${id}${seoURL(id, seo)}`;
			}, Domains.coach),
			user: new Path(
				(
					coachId: number | string,
					userId: number | string,
					studentSeo?: string | null,
					coachSeo?: string | null,
				) => {
					return `/coaching/${coachId}${seoURL(
						coachId,
						coachSeo,
					)}/${userId}${seoURL(userId, studentSeo)}`;
				},
				Domains.coach,
			),
		},
	},
	inbox: {
		index: new Path('/inbox', Domains.coach),
	},
};
