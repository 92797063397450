import {Command} from 'cmdk';
import {Icon, Stack} from '@halp/ui';
import style from './CommandPalette.module.css';
import type {ReactNode} from 'react';
import type {IconProp} from '@fortawesome/fontawesome-svg-core';

interface Props {
	children: ReactNode;
	value: string;
	onSelect?: (value: string) => void;
	icon?: IconProp;
}

export function Item({children, onSelect, value, icon}: Props) {
	return (
		<Command.Item onSelect={onSelect} value={value} className={style.Item}>
			<Stack justifyContent="flex-start" spacing="sm" fullWidth>
				{icon ? <Icon icon={icon} color="black" /> : null}
				{children}
			</Stack>
		</Command.Item>
	);
}
