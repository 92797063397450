import {isBrowser} from '@halp/util';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class Path<T extends (...args: any[]) => string> {
	public constructor(
		private path: string | T,
		private domain?: string,
		private resolver?: (path: string, domain?: string) => string, // eslint-disable-next-line no-empty-function
	) {}

	public url(...args: Parameters<T>) {
		const path = typeof this.path === 'string' ? this.path : this.path(...args);
		if (this.resolver) {
			return this.resolver(path, this.domain);
		}

		const domain =
			this.domain && isBrowser() && window.location.host !== this.domain
				? `${window.location.protocol}//${this.domain}`
				: '';

		return `${domain}${path}`;
	}
}
