import {faCalendar} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import {formatDateTime} from '@halp/util';
import {Icon} from '../Icon';
import {Button} from '../Button';
import {Calendar} from '../Calendar';
import {Popover} from '../Popover';
import style from './DatePicker.module.css';
import type {CalendarProps} from '../Calendar';

interface TriggerProps {
	triggerDisabled?: boolean;
	placeholder?: string;
	startMonth?: Date;
	endMonth?: Date;
	textWrap?: 'nowrap';
}

export type Props = CalendarProps & TriggerProps;

function PickerTrigger({
	triggerDisabled,
	placeholder,
	textWrap,
	...props
}: Props) {
	let content = placeholder;
	if (props.mode === 'single' && props.selected) {
		content = formatDateTime(props.selected, 'DateCompact', false);
	} else if (
		props.mode === 'range' &&
		props.selected?.from &&
		props.selected?.to
	) {
		const {from, to} = props.selected;
		content = `${formatDateTime(from, 'DateCompact', false)} - ${formatDateTime(
			to,
			'DateCompact',
			false,
		)}`;
	} else if (props.mode === 'multiple' && props.selected) {
		content = props.selected
			.map((date) => formatDateTime(date, 'DateCompact', false))
			.join(', ');
	}

	return (
		<Button
			className={classNames(
				style.Trigger,
				textWrap === 'nowrap' && style.NoWrap,
			)}
			disabled={triggerDisabled}
		>
			<Icon icon={faCalendar} /> {content}
		</Button>
	);
}

export function DatePicker({...props}: Props) {
	const trigger = <PickerTrigger {...props} />;

	return (
		<Popover trigger={trigger} className={style.Popover}>
			<Calendar {...props} />
		</Popover>
	);
}
