import {useQueryClient} from '@tanstack/react-query';
import {useGraphQLRequest} from './client';
import type {UseQueryOptions} from './use-query';
import type {QueryKey} from '@tanstack/react-query';
import type {TypedDocumentNode} from '@graphql-typed-document-node/core';

export function useImperativeQuery<Result, Variables>(
	document: TypedDocumentNode<Result, Variables>,
) {
	const request = useGraphQLRequest();
	const queryClient = useQueryClient();
	return (options?: UseQueryOptions<Result, Variables>) => {
		const variables = options?.variables ?? undefined;
		const queryKey = [{document, variables}] as QueryKey;
		const queryFn = async () => {
			return await request(document, variables);
		};
		return queryClient.fetchQuery({queryKey, queryFn, ...options});
	};
}
