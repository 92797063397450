import {useCallback, type ReactNode} from 'react';
import {Shell, SidebarNav} from '@halp/ui';
import {useUser} from '@halp/foundation/Users';
import {imgLogoWhite} from '@halp/images';
import style from './AppShell.module.css';
import {SideNav} from './SideNav';

export function AppShell({children}: {children: ReactNode}) {
	const {user} = useUser();

	const nav = useCallback(
		(_mobile: boolean, setOpenMenu: (open: boolean) => void) => {
			if (user?.coachProfile != null) {
				return <SideNav setOpenMenu={setOpenMenu} />;
			}
			return <SidebarNav logo={imgLogoWhite} items={[]} />;
		},
		[user],
	);

	return (
		<Shell
			flexNav={false}
			sideNav={nav}
			hideTopSideNav={user == null}
			contentClassName={style.AppContentAdmin}
		>
			{children}
		</Shell>
	);
}
