import {faCircle} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import ReactSlider from 'react-slider';
import {useState} from 'react';
import {CSSVariants} from '@halp/util';
import {Icon} from '../../Icon';
import {Stack} from '../../Stack';
import {Heading} from '../../Typography';
import {Surface} from '../../Surface';
import style from '../RangeSlider.module.css';
import {Link} from '../../Link';

export interface RangeStep<T> {
	label: string;
	value: T;
}

interface Props<T> {
	header: string;
	steps: RangeStep<T>[];
	lowerBoundIndex?: number;
	upperBoundIndex?: number;
	onChange?: (lowerBound: T, upperBound: T) => void;
	markClassName?: string;
	headerSpacing?: 'xs' | 'sm' | 'md';
	onReset?: () => void;
}

export function StepRangeSlider<T>({
	header,
	steps,
	lowerBoundIndex,
	upperBoundIndex,
	markClassName,
	headerSpacing = 'xs',
	onChange,
	onReset,
}: Props<T>) {
	const [lowerBound, setLowerBound] = useState<T>(
		steps[lowerBoundIndex ?? 0].value,
	);
	const [upperBound, setUpperBound] = useState<T>(
		steps[upperBoundIndex ?? steps.length - 1].value,
	);

	return (
		<Stack
			direction="column"
			spacing={headerSpacing}
			fullWidth
			alignItems="stretch"
			justifyContent="center"
		>
			<Stack justifyContent="space-between">
				<Heading type="h5" color="grey">
					{header}
				</Heading>
				<Link
					className={style.Reset}
					onClick={() => {
						onReset?.();
						setLowerBound(steps[0].value);
						setUpperBound(steps[steps.length - 1].value);
					}}
				>
					Reset
				</Link>
			</Stack>
			<Stack
				direction="column"
				fullWidth
				alignItems="center"
				spacing="none"
				justifyContent="center"
			>
				<Surface className={style.SliderContainer} shadow="none">
					<ReactSlider
						className={classNames(
							style.HorizontalSlider,
							style.SliderWithMarks,
						)}
						marks={steps.map((_step, index) => index)}
						min={0}
						max={steps.length - 1}
						renderTrack={(props, state) => (
							<div
								{...props}
								className={classNames(
									style.Track,
									CSSVariants(style, 'Track', state.index) ?? undefined,
								)}
							/>
						)}
						onChange={(value) => {
							setLowerBound(steps[value[0]].value);
							setUpperBound(steps[value[1]].value);

							if (onChange) {
								onChange(steps[value[0]].value, steps[value[1]].value);
							}
						}}
						value={[lowerBoundIndex ?? 0, upperBoundIndex ?? steps.length - 1]}
						ariaLabel={['Lower thumb', 'Upper thumb']}
						ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
						renderThumb={(props, _state) => (
							<div {...props} className={style.ThumbContainer}>
								<Icon icon={faCircle} className={style.Thumb} />
							</div>
						)}
						renderMark={(props) => {
							const markClasses = classNames(
								style.Mark,
								steps[props.key as number].value === lowerBound ||
									steps[props.key as number].value === upperBound
									? style.MarkActive
									: '',
								markClassName,
							);
							return (
								<div {...props} className={markClasses}>
									{steps[props.key as number].label}
								</div>
							);
						}}
						pearling
						withTracks
						thumbClassName={style.Thumb}
					/>
				</Surface>
			</Stack>
		</Stack>
	);
}
