import {useEffect, useState} from 'react';
import {getCountryNames} from './countries';

export function useCountryNames() {
	const [countryNames, setCountryNames] = useState<string[]>([]);

	useEffect(() => {
		getCountryNames().then(setCountryNames);
	}, [setCountryNames]);

	return countryNames;
}
