import {useState, useContext, useEffect} from 'react';
import {SocketContext} from '../websockets/SocketProvider';
import type {Channel} from 'phoenix';

export function useChannel(channelName: string) {
	const [channel, setChannel] = useState<Channel | null>(null);
	const socket = useContext(SocketContext);

	useEffect(() => {
		if (!socket) return;
		const channel = socket.channel(channelName);

		channel.join().receive('ok', () => {
			setChannel(channel);
		});

		return () => {
			channel.leave();
			setChannel(null);
		};
	}, [socket, channelName]);

	return channel;
}
