import {useState, useEffect} from 'react';
import classNames from 'classnames';
import {useRouter} from '@halp/ui/router';
import {useImperativeQuery} from '@halp/api/graphql';
import {friendlyURI} from '@halp/util';
import {Link, Paragraph, Stack} from '@halp/ui';
import type {
	CoachingFunnelStage,
	QualificationStatus,
} from '@halp/util/constants';
import {useI18n} from '../../i18n';
import {StudentIndexDocument} from '../../Students/StudentsTable/StudentIndex.query';
import {useUser, useUserType} from '../../Users';
import {Paths} from '../../Paths';
import {StudentFilters} from '../../Students/StudentFilters';
import {Item} from './Item';
import style from './CommandPalette.module.css';

interface Student {
	id: string;
	name?: string | null;
	email?: string | null;
	phone?: string | null;
}

interface Props {
	search: string;
	closePalette: VoidFunction;
}

export function SearchStudents({search, closePalette}: Props) {
	const {t} = useI18n();
	const router = useRouter();
	const userType = useUserType();
	const {user} = useUser();

	const [students, setStudents] = useState<Student[] | null>(null);
	const [coachId, setCoachId] = useState<string | null>(
		userType === 'coach' ? (user?.coachProfile?.id ?? null) : null,
	);
	const [qualStatusFilter, setQualStatusFilter] = useState<
		QualificationStatus | undefined
	>();
	const [intakePeriodFilter, setIntakePeriodFilter] = useState<string[]>([]);
	const [funnelStageFilter, setFunnelStageFilter] =
		useState<CoachingFunnelStage>();

	const studentSearchQuery = useImperativeQuery(StudentIndexDocument);

	useEffect(() => {
		async function searchStudents(search: string) {
			const data = await studentSearchQuery({
				variables: {
					search: search,
					limit: 25,
					coachId: coachId,
					qualificationStatus: qualStatusFilter,
					intakePeriods: intakePeriodFilter,
					funnelStage: funnelStageFilter,
				},
			});

			const students = data.students.edges
				? data.students.edges.map((student) => ({
						id: student.user.id,
						name: `${student.user.first} ${student.user.last}`,
						email: student.user.email,
						phone: student.user.phone,
					}))
				: null;

			setStudents(students);
		}

		searchStudents(search);
	}, [
		search,
		studentSearchQuery,
		coachId,
		qualStatusFilter,
		intakePeriodFilter,
		funnelStageFilter,
	]);

	return (
		<>
			<Stack
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing="sm"
				fullWidth
				className={style.FilterList}
			>
				<Link>
					<div
						onClick={() =>
							coachId
								? setCoachId(null)
								: setCoachId(user?.coachProfile?.id ?? null)
						}
						className={classNames(
							style.FilterToggle,
							coachId && style.FilterToggled,
						)}
					>
						{userType === 'coach'
							? t('command_palette.student_search.coach_toggle')
							: t('command_palette.student_search.admin_toggle')}
					</div>
				</Link>
				<StudentFilters
					shouldPushQueryParams={false}
					filters={['funnelStage', 'qualStatus', 'intakePeriod']}
					qualificationStatus={qualStatusFilter}
					setQualStatus={setQualStatusFilter}
					intakePeriod={intakePeriodFilter}
					setIntakePeriod={setIntakePeriodFilter}
					funnelStage={funnelStageFilter}
					setFunnelStage={setFunnelStageFilter}
				/>
			</Stack>
			{students?.map((student) => (
				<Item
					key={student.id}
					value={`${student.id} ${student.name} ${student.phone} ${student.email} ${student.id}`}
					onSelect={() => {
						router.push(
							Paths.users.overview.index.url(
								'/coaching/all',
								student.id,
								friendlyURI(student.name),
								userType,
							),
						);
						closePalette();
					}}
				>
					<Stack justifyContent="space-between" fullWidth>
						<Stack justifyContent="flex-start" spacing="xs">
							<Paragraph>{student.name}</Paragraph>
							<div className={style.Label}>
								<Paragraph>{student.id}</Paragraph>
							</div>
						</Stack>
						<Paragraph>{`${student.phone} - ${student.email}`}</Paragraph>
					</Stack>
				</Item>
			))}
		</>
	);
}
