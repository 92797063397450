import {faX} from '@fortawesome/free-solid-svg-icons';
import {Stack, Surface, Heading, Icon, Link} from '@halp/ui';
import type {StudentDocumentTypes} from '@halp/util/constants';
import {DOCUMENT_TYPES} from '@halp/util/constants';
import style from './Document.module.css';

interface Props {
	documents: StudentDocumentTypes[];
	onDelete: (document: string) => void;
}

export function SelectedDocumentType({documents, onDelete}: Props) {
	return (
		<Stack
			direction="column"
			spacing="sm"
			justifyContent="flex-start"
			fullWidth
		>
			{documents.map((document) => {
				return (
					<Surface
						border="subdued"
						shadow="none"
						key={document}
						className={style.RequiredDoc}
					>
						<Stack
							direction="row"
							justifyContent="space-between"
							fullWidth
							spacing="xs"
						>
							<Heading type="h6" weight="medium">
								{DOCUMENT_TYPES[document]}
							</Heading>
							<Link onClick={() => onDelete(document)}>
								<Icon icon={faX} />
							</Link>
						</Stack>
					</Surface>
				);
			})}
		</Stack>
	);
}
