import {useContext, createContext} from 'react';
import {
	ClientError,
	GraphQLClient as GraphQLRequestClient,
} from 'graphql-request';
import type {Variables as GraphQLVariables} from 'graphql-request';
import type {TypedDocumentNode} from '@graphql-typed-document-node/core';
import type {GraphQLError} from 'graphql-request/dist/types';

export class GraphQLClient extends GraphQLRequestClient {
	public authCallback?: () => Promise<boolean>;
}

export const GraphQLClientContext = createContext<{
	client: GraphQLClient;
	errorCallback: (error: unknown) => void;
} | null>(null);

export function useGraphQL() {
	const context = useContext(GraphQLClientContext);
	if (!context) {
		throw new Error('[GraphQL]: Called outside of the GraphQL provider');
	}
	return context;
}

export function useGraphQLRequest() {
	const {client, errorCallback} = useGraphQL();

	return async function request<Result, Variables>(
		document: TypedDocumentNode<Result, Variables>,
		variables?: GraphQLVariables,
	) {
		try {
			return await client.request<Result>(document, variables);
		} catch (error) {
			if (error instanceof ClientError) {
				const authError = error.response?.errors?.find(
					(err: GraphQLError) => err.message === 'unauthorized',
				);
				if (
					(error.response.statuscode === 401 || authError) &&
					client.authCallback
				) {
					const retry = await client.authCallback();
					if (retry) {
						return await client.request<Result>(document, variables);
					}
				}
			}
			errorCallback(error);
			throw error;
		}
	};
}
