import {createContext, useEffect, useState} from 'react';
import {Socket} from 'phoenix';

export const SocketContext = createContext<Socket | null>(null);

interface Props {
	children: React.ReactNode;
	url: string;
	options: {params: {token: string | null}};
}

export function SocketProvider({url, options, children}: Props) {
	const [socket, setSocket] = useState<Socket | null>(null);

	useEffect(() => {
		if (!options.params.token) return;
		const socket = new Socket(url, options);
		socket.connect();
		setSocket(socket);

		return () => {
			socket.disconnect();
			setSocket(null);
		};
	}, [options, url]);

	return (
		<SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
	);
}
