import {
	useMutation as useReactQueryMutation,
	useQueryClient,
} from '@tanstack/react-query';
import {useGraphQLRequest} from './client';
import type {UseMutationOptions} from '@tanstack/react-query';
import type {Variables as GraphQLVariables} from 'graphql-request';
import type {TypedDocumentNode} from '@graphql-typed-document-node/core';
import type {GraphQLDocumentQueryKey} from '.';

interface Options<Result, Variables>
	extends Omit<
		UseMutationOptions<Result, unknown, Variables, unknown>,
		'mutationFn'
	> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	invalidateQueries?: GraphQLDocumentQueryKey<any, any>[];
}

export function useMutation<Result, Variables extends GraphQLVariables>(
	document: TypedDocumentNode<Result, Variables>,
	options?: Options<Result, Variables>,
) {
	const request = useGraphQLRequest();
	const queryClient = useQueryClient();
	const mutationFn = async (variables: Variables) => {
		return await request(document, variables);
	};

	const {mutate, ...mutation} = useReactQueryMutation({
		mutationFn,
		...options,
		onSuccess: (data, variables, context) => {
			if (options?.onSuccess) {
				options.onSuccess(data, variables, context);
			}
			if (options?.invalidateQueries) {
				for (let query of options.invalidateQueries) {
					queryClient.invalidateQueries({queryKey: [query]});
				}
			}
		},
	});

	return [mutate, mutation] as const;
}
