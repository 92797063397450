import {useState} from 'react';
import {type RangeStep, StepRangeSlider, Dropdown} from '@halp/ui';
import {useI18n} from '../../../i18n';
import style from './ProgramLengthRangeSelector.module.css';
import type {ProgramLength} from '../../types';

interface Props {
	onChange: (lowerBound: ProgramLength, upperBound: ProgramLength) => void;
	minLength?: ProgramLength;
	maxLength?: ProgramLength | null;
	placeholder?: string;
}

const STEPS: RangeStep<ProgramLength>[] = [
	{label: '0', value: {year: 0, month: 0, week: 0}},
	{label: '6m', value: {year: 0, month: 6, week: 0}},
	{label: '1y', value: {year: 1, month: 0, week: 0}},
	{label: '2y', value: {year: 2, month: 0, week: 0}},
	{label: '3y', value: {year: 3, month: 0, week: 0}},
	{label: '4y', value: {year: 4, month: 0, week: 0}},
	{label: '>5y', value: {year: 100, month: 0, week: 0}},
];

export function ProgramLengthRangeSelector({
	minLength,
	maxLength,
	onChange,
	placeholder,
}: Props) {
	const {t} = useI18n();
	const [lowerBound, setLowerBound] = useState<ProgramLength>(
		minLength ?? {
			year: 0,
			month: 0,
			week: 0,
		},
	);
	const [upperBound, setUpperBound] = useState<ProgramLength>(
		maxLength ?? {
			year: 100,
			month: 0,
			week: 0,
		},
	);

	const handleChange = (
		lowerBound: ProgramLength,
		upperBound: ProgramLength,
	) => {
		setLowerBound(lowerBound);
		setUpperBound(upperBound);
	};

	const lowerBoundIndex = STEPS.findIndex(
		(step) =>
			step.value.year === lowerBound.year &&
			step.value.month === lowerBound.month &&
			step.value.week === lowerBound.week,
	);

	const upperBoundIndex = STEPS.findIndex(
		(step) =>
			step.value.year === upperBound.year &&
			step.value.month === upperBound.month &&
			step.value.week === upperBound.week,
	);

	return (
		<Dropdown
			onClick={() => null}
			caret
			toggle={
				maxLength && minLength
					? `${STEPS[lowerBoundIndex].label} - ${STEPS[upperBoundIndex].label}`
					: placeholder || t('program_length_range_selector.placeholder')
			}
			closeOnOutsideClick
			toggleClassName={style.Dropdown}
			wrapperClassName={style.Wrapper}
			toggleBarClassName={style.ToggleBar}
			onOutsideClick={() => onChange(lowerBound, upperBound)}
		>
			<StepRangeSlider<ProgramLength>
				header={t('program_length_range_selector.header')}
				steps={STEPS}
				lowerBoundIndex={lowerBoundIndex}
				upperBoundIndex={upperBoundIndex}
				onChange={handleChange}
				onReset={() => {
					setLowerBound(STEPS[0].value);
					setUpperBound(STEPS[STEPS.length - 1].value);
					onChange(STEPS[0].value, STEPS[STEPS.length - 1].value);
				}}
			/>
		</Dropdown>
	);
}
